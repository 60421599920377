import * as React from 'react';
import { Dialog, DialogActions, DialogContent} from '@mui/material';
import StandardButton from './standardButton';
import { Delete } from '@mui/icons-material';
import axios from 'axios';

export default function DeleteSurveyAnswersDialog({interviewees}) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const deleteAll = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
      const url = `https://dev.retailhub.ai/api/v4/advisory/interviewee-survey`;

      const response = await axios.delete(url, {
        data: { 
          interviewee_survey_ids: interviewees.map(i => { return Number(i.interviewee_survey_id)}) 
        },
        headers: { Authorization: `Bearer ${token}` }
      });

      // console.log(response)
    } catch (err) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setOpen(false);
    }
  }
  
  const deleteIntervieweeAnswer = async (id) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
      const url = `https://dev.retailhub.ai/api/v4/advisory/interviewee-survey`;

      const response = await axios.delete(url, {
        data: {
          interviewee_survey_ids: [Number(id)]
        },
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
      });

      // console.log(response)
    } catch (err) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setOpen(false);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <React.Fragment>
      <StandardButton 
        text="Elimina risposte"
        width="350px" 
        type="error"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: "16px !important",
            overflow: 'hidden',
            backgroundColor: "#191919"
          }
        }}
      >
        <DialogContent sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919"}}>
          <div className='flex flex-col gap-10 justify-center items-center w-full'>
            <div className='flex flex-col gap-2 justify-center items-center w-full'>
              <h1 className="text-xl font-bold w-full">Confermi di voler eliminare l'invio e tutti gli esiti correlati?</h1>
              <span className="text-white w-full">Questa operazione sarà irreversibile.</span>
            </div>
            <div className='flex flex-col gap-1 w-full'>
              {
                !!interviewees && 
                interviewees.map(na =>
                  <div key={na.interviewee_id} className='flex w-full justify-between items-center text-white py-2'>
                    <span>{na.first_name} {na.last_name}</span>
                    <Delete onClick={() => {deleteIntervieweeAnswer(na.interviewee_survey_id)}} sx={{cursor: 'pointer'}}/>
                  </div>
                )
              }
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919", paddingTop: "40px", justifyContent: 'center', gap: '50px'}}>
          <StandardButton variant='outlined' onClick={handleClose} text="Annulla" width={"300px"} type={"secondary"}/>
          <StandardButton onClick={() => {deleteAll()}} type={'error'} text="Elimina Tutti" width={"300px"}/>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
import React, { useState } from "react";
import { Card, CardMedia, CardContent, Typography, Chip, IconButton } from "@mui/material";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import CountryFlag from "./countryFlag";
import axios from "axios"; // Import axios to handle bookmark requests

function StartupsCard({ id, name, year, description, logo, countryCode, tags, link, isBookmarked }) {
  const [saved, setSaved] = useState(isBookmarked); // Set initial state based on the prop

  const handleSaveToggle = async () => {
    try {
      if (saved) {
        // If currently saved, send DELETE request to remove from bookmarks
        await axios.delete(`https://dev.retailhub.ai/api/v4/innovations/${id}/bookmark`);
      } else {
        // If not saved, send POST request to add to bookmarks
        await axios.post(`https://dev.retailhub.ai/api/v4/innovations/${id}/bookmark`);
      }
      setSaved(!saved); // Toggle the saved state
    } catch (error) {
      console.error('Failed to update bookmark status:', error);
    }
  };

  return (
    <a href={link} className="snap-start hover:no-underline"> {/* Use the link prop here */}
      <Card 
        className="flex flex-col p-5 border border-solid border-white border-opacity-30 relative"
        sx={{color: 'white', backgroundColor: 'transparent', height: "100%"}}
      >
        {/* Bookmark Icon in the top-right corner */}
        <IconButton
          onClick={(e) => {
            e.preventDefault(); // Prevent the link from being followed
            handleSaveToggle();
          }}
          sx={{ position: "absolute", top: "10px", right: "10px", zIndex: 1 }}
        >
          {saved ? (
            <BookmarkOutlinedIcon sx={{ color: "rgb(var(--global-color-primary))", fontSize: "24px" }} />
          ) : (
            <BookmarkBorderOutlinedIcon sx={{ color: "rgb(var(--global-color-primary))", fontSize: "24px" }} />
          )}
        </IconButton>

        <div className="flex gap-4 items-center">
          <CardMedia
            className="!w-20 h-20 rounded-lg"
            component="img"
            image={logo}
            alt={name}
          />
          <CardContent>
            <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: "Urbanist, sans-serif" }}>
              {name} ({year})
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: "Urbanist, sans-serif", maxHeight: "100px", overflow: 'hidden' }}>
              {description}
            </Typography>
          </CardContent>
        </div>
        <div className="flex w-full justify-between items-center">
          <div className="flex flex-wrap w-full gap-2 items-center">
            {tags.map(tag => 
              <Chip 
                label={tag.label}
                variant={tag.variant}
                sx={{
                  fontFamily: "Urbanist, sans-serif", 
                  border: "1px solid rgb(255, 255, 255, .3)", 
                  backgroundColor: tag.variant === "filled" ? "rgb(var(--global-color-primary))" : "transparent",
                  color: tag.variant === "filled" ? "black" : "white",
                  textTransform: "capitalize"
                }}
                key={tag.id}
              />
            )}
          </div>
          <div className="flex flex-col gap-2 items-center">
            <span className="font-semibold">Country</span>
            <CountryFlag countryCode={countryCode} />
          </div>
        </div>
      </Card>
    </a>
  );
}

export default StartupsCard;

import React from "react";
import RadarChart from "../radarChart";
import { Slider } from "@mui/material";

export default function AdvisoryTotalRankingWidget({ startups, datasets, labels }) {
  return (
    <div className="flex flex-wrap xl:flex-nowrap gap-5 items-center bg-black rounded-2xl w-full max-w-full">
      <RadarChart datasets={datasets} labels={labels} showLegend={true}/>
      <div className="flex flex-col gap-5 items-center w-full">
        <h2 className="text-3xl font-bold">TOTAL RANKING</h2>
        {
          startups.map((s, index) => 
            <div className="flex flex-col gap-0 items-center justify-center w-4/5" key={index}>
              <h3 className="text-xl font-semibold w-full">{s.innovation_description}</h3>
              <Slider
                key={s.innovation_id}
                defaultValue={0}
                value={Math.round(s.score_mean)} // Use score_mean multiplied by 10
                min={0}
                max={10}
                valueLabelDisplay="on"
                sx={{ 
                  color: '#01A17F', // You can dynamically adjust this based on the startup if needed
                  height: "8px",
                  '& .MuiSlider-valueLabelOpen': {
                    backgroundColor: '#191919',
                    color: 'white',
                    fontWeight: 'bold',
                    fontFamily: 'Urbanist, sans-serif'
                  },
                }}
              />
              <div className="flex gap-4 items-center justify-between w-full">
                <span className="min-w-fit">0</span>
                <span className="min-w-fit">10</span>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

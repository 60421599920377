import React from "react";
import axios from 'axios';
import { Button } from '@mui/material';
import Step1 from '../components/steps/Step1';
import Step2 from '../components/steps/Step2';
import Step3 from '../components/steps/Step3';
import Step4 from '../components/steps/Step4';
import Step5 from '../components/steps/Step5';
import Step6 from '../components/steps/Step6';

export default function AdvisoryChat() {
  const [currentStep, setCurrentStep] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [chatMessages, setChatMessages] = React.useState([]);
  const [innovations, setInnovations] = React.useState([]);
  const [intervieweeSurveyId, setIntervieweeSurveyId] = React.useState(null);
  const [SurveyId, setSurveyId] = React.useState(null);
  const [message, setMessage] = React.useState(false); // Aggiungi stato per message
  
  // Effetto per prendere lo step corrente all'inizio
  React.useEffect(() => {
    const fetchCurrentStep = async () => {
      try {
        const token = localStorage.getItem('token');

        // Chiamata GET all'endpoint /advisory/steps/current
        const response = await axios.get('https://dev.retailhub.ai/api/v4/advisory/steps/current', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        let stepData = response.data;

        if (stepData === null || stepData.id === null) {
          // Se la risposta è null, facciamo partire lo step 1
          const postResponse = await axios.post(
            'https://dev.retailhub.ai/api/v4/advisory/steps',
            { "new_step": 1 },
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            }
          );
          stepData = postResponse.data;
        }

        // Imposta lo step corrente in base alla risposta API
        setCurrentStep(parseInt(stepData.id));

        // Imposta i messaggi di chat se presenti
        if (stepData.chat && stepData.chat.length > 0) {
          setChatMessages(stepData.chat);
        } else {
          // Se non ci sono messaggi, mostra un messaggio iniziale
          setChatMessages([
            {
              id: 0,
              text: "Type something to start chat",
              sender: "Server"
            }
          ]);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching current step:", error);
        setError(error.message || 'Failed to fetch data');
        setLoading(false);
      }
    };

    fetchCurrentStep();
  }, []);

  const handleNextStep = async (step, innovationsData = [], messageValue = message) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');

      // Effettua la POST per aggiornare lo step
      const response = await axios.post(
        'https://dev.retailhub.ai/api/v4/advisory/steps',
        { new_step: step },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log(`Step ${step} changed successfully:`, response.data);

      // Se lo step è 6, prendi l'interviewee_survey_id e salvalo
      if (step === 6 && response.data && response.data.interviewee_survey_id) {
        setIntervieweeSurveyId(response.data.interviewee_survey_id);
      }

      setSurveyId(response.data.survey_id);

      // Se ci sono innovations passate, aggiornale nello stato
      if (innovationsData.length > 0) {
        setInnovations(innovationsData); // Imposta le innovations nello stato
      }

      // Imposta lo stato del message
      setMessage(messageValue);

      // Aggiorna lo step corrente
      setCurrentStep(step);
    } catch (error) {
      console.error(`Error changing to step ${step}:`, error);
      setError('Errore durante il cambio di step');
    } finally {
      setLoading(false);
    }
  };

  // Usa useEffect per monitorare i cambiamenti di SurveyId
  React.useEffect(() => {
    if (SurveyId) {
      console.log("SurveyId aggiornato:", SurveyId);
    }
  }, [SurveyId]);

  // Funzione per renderizzare lo step corretto
  function renderStep() {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            onNext={handleNextStep}
          />
        );
      case 2:
        return (
          <Step2
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            onNext={handleNextStep}
            message={message} // Passa message allo Step2
          />
        );
      case 3:
        return (
          <Step3
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            onNext={handleNextStep}
            innovations={innovations}
            message={message} // Passa message allo Step3
          />
        );
      case 4:
        return (
          <Step4
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            onNext={handleNextStep}
            message={message} // Passa message allo Step4 se serve
          />
        );
      case 5:
        return (
          <Step5
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            onNext={handleNextStep}
            message={message} // Passa message allo Step4 se serve
          />
        );
      case 6:
        return (
          <Step6
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            intervieweeSurveyId={intervieweeSurveyId}
            SurveyId={SurveyId}
            onNext={handleNextStep}
          />
        );
      default:
        return <div>Ciao...</div>;
    }
  }

  return (
    <React.Fragment>
      <div className="bg-black h-full max-h-full flex flex-col items-center w-[100%] p-5">
        {/* Render step corrente */}
        {error ? <div>{error}</div> : renderStep()}
      </div>
    </React.Fragment>
  );
}

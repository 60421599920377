import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import axios from 'axios';
import BlogSection from '../components/blogSection';
import FlexibleDataFetcher from '../components/flexibileDataFetcher';

function ArticlePage() {
  const { id } = useParams(); // Get the article ID from the URL
  const [newsData, setNewsData] = useState([]);
  const [article, setArticle] = useState(null); // State for the current article
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Handle data fetch success for news
  const handleNewsFetchSuccess = (data) => {
    if (data && data.data) {
        setNewsData(data.data); // Save the fetched news data
    }
    setLoading(false);
  };

  // Handle data fetch error
  const handleDataFetchError = (err) => {
      setError(err.message || 'Failed to fetch data');
      setLoading(false);
  };

  // Fetch the article data when the component mounts
  useEffect(() => {
    const fetchArticle = async () => {
      setLoading(true); // Start loading
      setError(null); // Reset error state

      try {
        const token = localStorage.getItem('token'); // Fetch token
        if (!token) {
          throw new Error('Token not found. Please login.');
        }

        const response = await axios.get(`https://dev.retailhub.ai/api/v4/news/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        setArticle(response.data.data); // Assuming the article data is inside `data`
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError(err.message || 'Failed to fetch article data');
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  // Show error message if fetching fails
  if (error) {
    return <Typography variant="h4" sx={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>{error}</Typography>;
  }

  // Show loading indicator
  if (loading) {
    return <Typography variant="h4" sx={{ textAlign: 'center', marginTop: '20px' }}>Loading article...</Typography>;
  }

  // If article is not found
  if (!article) {
    return <Typography variant="h4" sx={{ textAlign: 'center', marginTop: '20px' }}>Article not found</Typography>;
  }

  return (
    <div className="flex flex-row flex-wrap gap-5" style={{ padding: '25px' }}>
      <div className="bg-black-l p-5 flex flex-col bg-black-l basis-full gap-5 justify-center items-center rounded-lg relative">
        
        {/* Article Title and Date */}
        <h3>{article.title}</h3>
        
        <p style={{margin: "0"}}>{new Date(article.created_at).toLocaleDateString()}</p>

        {/* Article Category */}
        <div className="flex flex-row justify-center">
          <div className="CatPill">{article.category || 'News'}</div>
        </div>   

        {/* Article Image */}
        <img style={{borderRadius: "8px"}} src={article.cover} alt="post"></img>

        {/* Article Content */}
        <div className="px-5">
          <p style={{textAlign: "center"}}>{article.content}</p>
        </div>
      </div>      

      {/* Box Articoli */}
      <div className="bg-black-l p-5 flex basis-full flex-col gap-5 justify-center items-center rounded-lg relative">
          <h2>Related Articles</h2>
          <div className="flex flex-row gap-3">
              <BlogSection articles={newsData} showPagination={false} />
          </div>
          <Button component="a" href="/blog" variant="contained" sx={{ fontSize: '16px', fontFamily: 'Urbanist, sans-serif', backgroundColor: '#C5FF55', color: '#191919', fontWeight: 700, textTransform: 'capitalize' }}>
              View All &rarr;
          </Button>
      </div>

      <FlexibleDataFetcher
        endpoint="news"
        params={{ per_page: 3 }}
        onSuccess={handleNewsFetchSuccess}
        onError={handleDataFetchError}
      />
    </div>  
  );
}

export default ArticlePage;

import React, { useState, useEffect } from "react";
import axios from "axios";
import StartupsCard from "../components/startupCard";
import SectorFilterDialog from "../components/sectorFilterDialog";
import { Button, Pagination, MenuItem, Select, Checkbox, FormControl, InputLabel, ListItemText, FormControlLabel } from "@mui/material";
import NewDiscoveryChip from "../components/newDiscoveryChip";
import { useLocation } from "react-router-dom";

function BookmarksStartups() {
  const [bookmarkedStartups, setBookmarkedStartups] = useState([]); // Holds the bookmarked startups data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [page, setPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); // Total pages
  const [perPage] = useState(9);
  const [orderDir] = useState("desc");
  const [orderBy] = useState("added");
  const [filters, setFilters] = useState({}); // Filter data from API
  const [selectedFilters, setSelectedFilters] = useState({
    chat: "",
    business_models: [],
    stages: [], // renamed to "status"
    company_types: [],
    target_markets: [],
    tags: [],
    order_by: "",
    order_dir: "",
    countries: [],
    year_min: "",
    year_max: ""
  });

  const location = useLocation();

  // Function to parse query params
  const parseQueryParams = (search) => {
    const params = new URLSearchParams(search);
    const parsedParams = {};
    for (const [key, value] of params.entries()) {
      parsedParams[key] = value;
    }
    return parsedParams;
  };

  // Function to fetch filter data
  const fetchFilterData = async () => {
    try {
      const response = await axios.get('https://dev.retailhub.ai/api/v4/values/innovation-filters');
      setFilters(response.data.data);
    } catch (err) {
      console.error("Error loading filters", err);
    }
  };

  // Function to fetch data with applied filters
  const fetchData = async (page) => {
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found. Please log in.');
      }

      const queryParams = new URLSearchParams();

      queryParams.append('page', page);
      queryParams.append('per_page', perPage);
      queryParams.append('order_dir', orderDir);
      queryParams.append('order_by', orderBy);
      queryParams.append('bookmarked', 'true');

      // Add filters to query params

      if (selectedFilters.chat !== "") {
        queryParams.append('chat', selectedFilters.chat);
      }

      if (selectedFilters.countries.length > 0) {
        selectedFilters.countries.forEach(countryId => {
          queryParams.append('country[]', countryId);
        });
      }

      if (selectedFilters.year_min) {
        queryParams.append('year_min', selectedFilters.year_min);
      }

      if (selectedFilters.year_max) {
        queryParams.append('year_max', selectedFilters.year_max);
      }

      if (selectedFilters.business_models.length > 0) {
        queryParams.append('business_model', selectedFilters.business_models[0]);
      }

      if (selectedFilters.stages.length > 0) {
        queryParams.append('status', selectedFilters.stages[0]);
      }

      if (selectedFilters.company_types.length > 0) {
        selectedFilters.company_types.forEach(id => {
          queryParams.append('company_types[]', id);
        });
      }

      if (selectedFilters.target_markets.length > 0) {
        selectedFilters.target_markets.forEach(id => {
          queryParams.append('target_markets[]', id);
        });
      }

      if (selectedFilters.tags.length > 0) {
        selectedFilters.tags.forEach(id => {
          queryParams.append('tags[]', id);
        });
      }

      // Add query params from URL
      const queryParamsFromURL = parseQueryParams(location.search);
      Object.entries(queryParamsFromURL).forEach(([key, value]) => {
        if (!queryParams.has(key)) {
          queryParams.append(key, value);
        }
      });

      const url = `https://dev.retailhub.ai/api/v4/innovations?${queryParams.toString()}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const data = response.data;
      setBookmarkedStartups(data.data);
      setTotalPages(parseInt(data.last_page));
    } catch (err) {
      setError(err.message || "Error loading data");
    } finally {
      setLoading(false);
    }
  };

  // Function to reset filters
  const resetFilters = () => {
    setSelectedFilters({
      chat: "",
      business_models: [],
      stages: [], // renamed to "status"
      company_types: [],
      target_markets: [],
      tags: [],
      order_by: "",
      order_dir: "",
      countries: [],
      year_min: "",
      year_max: "",
    });
    setPage(1);
  };

  useEffect(() => {
    fetchData(page);
  }, [page, selectedFilters, location.search]);

  useEffect(() => {
    fetchFilterData();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleFilterChange = (filterName, selectedValues) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterName]: selectedValues
    }));
  };

  // Map the startupsData to StartupsCard components
  const listStartups = bookmarkedStartups.map((s) => (
    <StartupsCard
      key={s.id}
      id={s.id} // Pass the startup ID to be used for bookmark toggle
      name={s.name}
      description={s.description ? s.description.replace(/<\/?[^>]+(>|$)/g, "") : "No description available"} // Remove HTML tags
      logo={s.logo_url || "https://dev.retailhub.ai/default-logo.png"} // Default logo if none
      countryCode={s.country?.iso || "US"} // Fallback to "US" if no country
      tags={s.taxonomies?.tags?.length > 0 
          ? s.taxonomies.tags.map((tag, index) => ({
              id: index,
              label: tag.name || "N/A",
              variant: "outlined"
            }))
          : [{ id: 0, label: "No tags", variant: "outlined" }]} // Fallback for no tags
      link={`/startups/startup/${s.id}`}
      isBookmarked={s.is_bookmarked} // Pass the initial bookmark status
    />
  ));

  return (
    <div className="bg-black p-5 h-full min-h-[100vh] flex flex-col gap-10">
      <NewDiscoveryChip />
      {/* Header */}
      <h1 className="text-6xl font-medium">Saved Startups</h1>

      {/* Filters and header */}
      <div className="flex flex-col gap-2">
        
        <div className="flex flex-wrap gap-4">
          <SectorFilterDialog />

          <FilterSelect
            label="Location"
            options={[{ id: "", name: "Reset" }, ...(filters.countries || [])]}
            selected={selectedFilters.countries}
            onChange={(selected) => handleFilterChange('countries', selected)}
          />

          <FilterSelect
            label="Type"
            options={[{ id: "", name: "Reset" }, ...(filters.company_types || [])]}
            selected={selectedFilters.company_types}
            onChange={(selected) => handleFilterChange('company_types', selected)}
          />

          <FilterSelect
            label="Target Markets"
            options={[{ id: "", name: "Reset" }, ...(filters.target_markets || [])]}
            selected={selectedFilters.target_markets}
            onChange={(selected) => handleFilterChange('target_markets', selected)}
          />

          <FilterSelect
            label="Business Models"
            options={[{ id: "", name: "Reset" }, ...(filters.business_models || [])]}
            selected={selectedFilters.business_models}
            onChange={(selected) => handleFilterChange('business_models', selected)}
          />

          <FilterSelect
            label="Status"
            options={[{ id: "", name: "Reset" }, ...(filters.stages || [])]}
            selected={selectedFilters.stages}
            onChange={(selected) => handleFilterChange('stages', selected)}
          />

          <FilterSelect
            label="Tags"
            options={[{ id: "", name: "Reset" }, ...(filters.tags || [])]}
            selected={selectedFilters.tags}
            onChange={(selected) => handleFilterChange('tags', selected)}
          />

          <Button variant="outlined" onClick={resetFilters} sx={{ ...buttonStyles }}>
            Reset All Filters
          </Button>
        </div>
      </div>

      <div className="flex flex-wrap gap-4">
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedFilters.chat === "true"}
              onChange={(event) => handleFilterChange('chat', event.target.checked ? "true" : "")}
              sx={{
                color: '#C5FF55',
                '&.Mui-checked': {
                  color: '#C5FF55', // Checked color
                },
                '& .MuiSvgIcon-root': {
                  fontFamily: 'Urbanist, sans-serif', // Custom font
                },
                '& .MuiCheckbox-root': {
                  border: '1px solid #C5FF55', // Checkbox border
                }
              }}
            />
          }
          label="Chat Available"
        />
      </div>

      {/* Startups list */}
      <div 
        className="grid gap-3" 
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gridGap: '15px',
        }}
      >
        <style>
          {`
            @media (min-width: 768px) {
              div.grid {
                grid-template-columns: repeat(3, 1fr) !important;
              }
            }
          `}
        </style>
        {loading ? (
          <p>Loading bookmarked startups...</p>
        ) : error ? (
          <p style={{ color: 'red' }}>{error}</p>
        ) : (
          bookmarkedStartups.length > 0 ? (
            listStartups // Render the list of bookmarked startups
          ) : (
            <p>No bookmarked startups found</p>
          )
        )}
      </div>

      <div className="flex justify-center mt-6">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          className="pagination"
        />
      </div>
    </div>
  );
}

const FilterSelect = ({ label, options, selected, onChange, multiple = true }) => {
  return (
    <FormControl sx={{ minWidth: 120 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple={multiple}
        value={selected || (multiple ? [] : "")}
        onChange={(e) => onChange(e.target.value)}
        renderValue={(selected) => {
          if (multiple) {
            return selected
              .map((sel) => options.find((option) => option.id === sel)?.name || "")
              .join(', ');
          } else {
            return options.find((option) => option.id === selected)?.name || "";
          }
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {multiple && <Checkbox checked={selected.indexOf(option.id) > -1} />}
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const buttonStyles = {
  fontFamily: "Urbanist, sans-serif",
  border: "1px solid rgb(255, 255, 255, .3)",
  color: "rgb(255, 255, 255, .3)"
};

export default BookmarksStartups;

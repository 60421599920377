import * as React from 'react'
import { TextField } from '@mui/material'

export default function StandardTextfield({variant, value, label, fullWidth, onChange, multiline, minRows, placeholder}) {
  return (
    <TextField
      variant={!!variant ? variant : "outlined"}
      value={value}
      label={label}
      placeholder={placeholder}
      focused={!!value ? true : false}
      fullWidth={!!fullWidth ? fullWidth : true}
      onChange={onChange}
      multiline={ !!multiline ? true : false}
      minRows={!!minRows ? minRows : 1}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#fff',
            fontFamily: 'Urbanist, sans-serif',
          },
          '&:hover fieldset': {
            borderColor: '#fff', 
          },
          '&.Mui-focused fieldset': {
            borderColor: '#fff', 
          },
        },
        '& .MuiOutlinedInput-input': {
          color: '#fff', 
          fontFamily: 'Urbanist, sans-serif',
        },
        '& .MuiOutlinedInput-input:-webkit-autofill': {
          backgroundColor: '#191919',
        },
        '& .MuiInputLabel-root': {
          color: '#fff',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#fff',
        },
      }}
    />
  )
}

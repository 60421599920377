import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

export default function BarChart({title, showYgrid, showXgrid, data}) {
  const datasets = !!data ? data.map(d => {return d.nr}) : undefined
  const label = !!data ? data[0].title : undefined
  const bgColor = !!data && data[0].status === "Agreement" ? '0, 255, 0' : !!data && data[0].status === "Uncertain" ? "255, 255, 0" : "255, 0, 0"
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");

    chartInstance.current = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
        datasets: [
          {
            label: !!label ? label : "Joor",
            data: !!datasets ? datasets : [12, 45, 78, 124, 12],
            backgroundColor: `rgb(${bgColor}, .5)`,
            borderColor: `rgb(${bgColor})`
          },
        ],
      },
      options: {
        scales: {
          x: {
            grid:{
              color: 'rgb(255, 255, 255, .1)'
            },
            ticks: {
              display: !!showXgrid ? showXgrid : false
            }
          },
          y: {
            grid:{
              color: 'rgb(255, 255, 255, .1)'
            },
            ticks: {
              display: !!showYgrid ? showYgrid : false
            },
            min: 0,
            max: 4
          }
        },
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: !!title ? true : false,
            text: !!title ? title : "",
            color: "white"
          }
        }
      }
    });
  }, []);

  return (
    <div className='w-full h-[150px] flex items-center justify-center'>
      <canvas ref={chartRef} />
    </div>
  );
}

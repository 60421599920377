import * as React from "react";
import klarnaLogo from '../assets/startups/klarna-logo.png'
import joorLogo from '../assets/startups/joor-logo.jpg'
import { TextField, InputAdornment, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';

const chats = [
  {
    id: 0,
    startup: 'Klarna',
    logo: klarnaLogo,
    country: "Sweden",
    messages: [
      {
        id: 0,
        text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
        new: true
      },
      {
        id: 1,
        text: `Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
        new: true
      },
      {
        id: 2,
        text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
        new: true
      },
    ]
  },
  {
    id: 1,
    startup: 'Joor',
    logo: joorLogo,
    country: "United States of America",
    messages: [
      {
        id: 0,
        text: 'Unicorn',
        new: true
      },
      {
        id: 1,
        text: 'CRM',
        new: false
      },
      {
        id: 2,
        text: 'Ecommerce',
        new: false
      }
    ]
  }
]

export default function Chats() {
  const [selectedChatMessages, setSelectedMessages] = React.useState([])
  const [newMessage, setNewMessage] = React.useState("")

  const handleChatSelection = (chat) => {
    setSelectedMessages([]); // Always start with an empty array
  };
  
  const handleNewMessage = (event) => {
    setNewMessage(event.target.value)
  }

  const handleAddMessage = () => {
    setSelectedMessages([...selectedChatMessages, { id: selectedChatMessages.length, text: newMessage, new: true}])
    setNewMessage("")
  }
 
  return (
    <React.Fragment>
      <div className="bg-black h-full min-h-[calc(100vh-104px)] flex">
        <div className="flex flex-col gap-2 w-60 min-w-60 h-full bg-[rgb(255,255,255,.2)] p-2">
        <TextField
          variant="outlined"
          placeholder="Search for startups"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon sx={{ color: '#818181' }} />
              </InputAdornment>
          ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#818181',
                fontFamily: 'Urbanist, sans-serif',
              },
              '&:hover fieldset': {
                borderColor: '#818181', 
              },
              '&.Mui-focused fieldset': {
                borderColor: '#818181', 
              },
            },
            '& .MuiOutlinedInput-input': {
              color: '#818181', 
              fontFamily: 'Urbanist, sans-serif',
            },
            '& .MuiInputLabel-root': {
              color: '#818181',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#818181',
              },
            }}
          />
          {
            chats.map(chat => 
              <Button 
                sx={{display: "flex", gap: "8px", alignItems: "center", position: "relative"}}
                key={chat.id}
                onClick={() => {handleChatSelection(chat)}}
              >
                <img src={chat.logo} alt={chat.startup} className="w-8 h-8 rounded-full object-cover" />
                <div className="flex flex-col gap-0 w-full text-left text-white normal-case">
                  <span>{chat.startup}</span>
                  <span className="text-[10px]">{chat.country}</span>
                </div>
                <div className="absolute top-5 right-0 text-white bg-red-500 p-1 rounded-full text-[8px] font-bold w-4 h-4 flex justify-center items-center">
                  {chat.messages.filter( m => m.new).length}
                </div>
              </Button>
              )
          }
        </div>
        {
          selectedChatMessages && (
            <div className="p-5 flex flex-col gap-5 w-full relative pb-24">
              <div className="flex flex-col gap-5 h-full justify-end overflow-hidden overflow-y-auto">
                {
                  selectedChatMessages.map(m => 
                    <div className="flex flex-col gap-1 items-end" key={m.id}>
                      <span className="font-semibold">You</span>
                      <div 
                        className="p-2 bg-gray-800 w-full rounded-2xl max-w-full"
                        key={m.id}
                      >
                        {m.text}
                      </div>
                    </div>
                  )
                }
              </div>
              <div className="absolute p-5 bottom-0 left-0 w-full bg-black flex gap-5">
                <TextField 
                  variant="outlined" 
                  fullWidth
                  multiline
                  value={newMessage} 
                  placeholder="Input here"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#818181',
                        fontFamily: 'Urbanist, sans-serif',
                      },
                      '&:hover fieldset': {
                        borderColor: '#818181',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#818181',
                      },
                    },
                    '& .MuiOutlinedInput-input': {
                      color: '#fff',
                      fontFamily: 'Urbanist, sans-serif',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#818181',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#818181',
                    },
                  }}
                  onChange={handleNewMessage}
                />
                <Button 
                  variant="filled" 
                  sx={{
                    backgroundColor: "rgb(var(--global-color-primary))", 
                    color: "black"
                  }}
                  onClick={handleAddMessage}
                >
                  <SendIcon />
                </Button>
              </div>
            </div>
          )
        }

      </div>
    </React.Fragment>
  );
}
import React, { useEffect, useRef } from "react";
import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import StandardTextfield from "../standardTextfield";
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';

export default function Step1({ chatMessages, setChatMessages, onNext }) {
  const [newMessage, setNewMessage] = React.useState("");
  const [error, setError] = React.useState(null);
  const [isTyping, setIsTyping] = React.useState(false);
  const [serverReplied, setServerReplied] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState(false); // Stato per il pulsante

  const token = localStorage.getItem('token');
  const messagesEndRef = useRef(null);

  // Scroll to bottom when chatMessages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages]);

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const response = await axios.get('https://dev.retailhub.ai/api/v4/advisory/steps/current', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
  
        const stepData = response.data;
  
        if (stepData.chat && stepData.chat.length > 0) {
          // Reverse the chat array to display messages in chronological order
          const reversedChat = stepData.chat.slice().reverse();
  
          const chatHistory = reversedChat.flatMap((chatItem, index) => ([
            { id: index * 2, text: chatItem.question, sender: "You" },
            { id: index * 2 + 1, text: chatItem.answer, sender: "Server" }
          ]));
  
          setChatMessages(chatHistory);
        } else {
          setChatMessages([{
            id: 0,
            text: "Type something to start chat",
            sender: "Server"
          }]);
        }
      } catch (error) {
        setError('Unable to load chat history.');
      }
    };
  
    fetchChatHistory();
  }, [setChatMessages, token]);
  

  const handleNewMessage = (event) => {
    setNewMessage(event.target.value);
  };

  const handleAddMessage = async () => {
    if (newMessage.trim() !== "") {
      setChatMessages(prevMessages => [
        ...prevMessages,
        { id: prevMessages.length, text: newMessage, sender: "You" }
      ]);

      setIsTyping(true);

      try {
        const payload = {
          message: newMessage
        };

        const response = await axios.post(
          'https://dev.retailhub.ai/api/v4/advisory/message',
          payload,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        setTimeout(() => {
          if (response.data && response.data.answer) {
            setChatMessages(prevMessages => [
              ...prevMessages,
              { id: prevMessages.length, text: response.data.answer, sender: "Server" }
            ]);
            setServerReplied(true);
          }
          setIsTyping(false);
        }, 1000);

      } catch (error) {
        setError('Error sending message');
        setIsTyping(false);
      }

      setNewMessage("");
    }
  };

  const handleGoToStep2 = () => {
    setButtonClicked(true); // Nascondi il pulsante
    setIsTyping(true); // Mostra l'effetto typing

    // Simula un breve ritardo per l'effetto typing prima di andare allo Step 2
    setTimeout(() => {
      onNext(2); // Vai allo Step 2
    }, 1000); // Ritardo di 1 secondo per simulare il typing
  };

  return (
    <div className="flex flex-col h-full w-full">
      {/* Header */}
      <div className="flex gap-2 p-5 pb-2 border-b border-white">
        <FaceRetouchingNaturalIcon />
        <span>AI - Survey</span>
      </div>

      {/* Chat Container */}
      <div className="flex flex-col flex-grow h-0">
        {/* Chat Messages Area */}
        <div className="flex flex-col flex-grow overflow-y-auto px-5">
          {chatMessages.map((m, index) => (
            <div
              key={`${m.sender}-${index}`}
              className={`flex flex-col gap-1 ${
                m.sender === "You" ? "items-end" : "items-start"
              }`}
            >
              <span className="font-semibold">{m.sender}</span>
              <div className="p-2 bg-gray-800 w-full rounded-2xl max-w-[80%] whitespace-pre-wrap">
                {m.sender === "Server" ? (
                  <ReactMarkdown>{m.text}</ReactMarkdown>
                ) : (
                  <span>{m.text}</span>
                )}
              </div>
            </div>
          ))}

          {/* Typing Indicator */}
          {isTyping && (
            <div className="flex flex-col gap-1 items-start">
              <span className="font-semibold">Server</span>
              <div className="p-2 bg-gray-800 rounded-2xl max-w-[80%] flex flex-col">
                <span>Typing...</span>
              </div>
            </div>
          )}

          {/* Step 2 Button - Nascondi se `buttonClicked` è true */}
          {!buttonClicked && serverReplied && (
            <div className="p-5 bg-gray-800 rounded-2xl max-w-[80%] mt-4">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "rgb(var(--global-color-primary))",
                  color: "black",
                  marginTop: "10px"
                }}
                onClick={handleGoToStep2} // Gestisci il click sul pulsante
                disabled={isTyping}
              >
                Go to Step 2
              </Button>
            </div>
          )}

          {/* Scroll Anchor */}
          <div ref={messagesEndRef} />
        </div>

        {/* Input Area */}
        <div className="p-5 w-full bg-black flex gap-5">
          <StandardTextfield
            variant="outlined"
            multiline={true}
            value={newMessage}
            placeholder="Input here"
            onChange={handleNewMessage}
            disabled={isTyping}
            style={{ flexGrow: 1 }}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "rgb(var(--global-color-primary))",
              color: "black"
            }}
            onClick={handleAddMessage}
            disabled={isTyping}
          >
            <SendIcon />
          </Button>
        </div>
      </div>

      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
}

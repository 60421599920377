import React from "react";
import PointChart from "../pointChart";

export default function SurveyPointChartRankingWidget({labels, data, startupName}) {
  return (
    <div className="flex flex-col items-center bg-black rounded-2xl w-full max-w-full p-5">
      <div className="flex flex-wrap xl:flex-nowrap gap-5 items-center w-full max-w-full">
        <div className="flex w-full items-center">
          <span className="text-xs font-black">{labels[0] || "VALUE"}</span>
          <PointChart title={labels[1] || "SEMPLICITY"} datasets={
            [
              {
                label: startupName,
                data: [
                  {
                    x: data.find(d => d.projection_name === labels[0])?.score_mean * 2.5,
                    y: data.find(d => d.projection_name === labels[1])?.score_mean * 2.5
                  }
                ]
              }
            ]
          }/>
        </div>
        <div className="flex w-full items-center">
          <span className="text-xs font-black">{labels[2] || "URGENCY"}</span>
          <PointChart 
            title={labels[3] || "IMPORTANCE"}
            datasets={
              [
                {
                  label: startupName,
                  data: [
                    {
                      x: data.find(d => d.projection_name === labels[2])?.score_mean * 2.5,
                      y: data.find(d => d.projection_name === labels[3])?.score_mean * 2.5
                    }
                  ]
                }
              ]
            }
          />
        </div>
      </div>
    </div>
  )
}
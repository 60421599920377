import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import ReactMarkdown from 'react-markdown';
import SendIcon from '@mui/icons-material/Send';
import StandardTextfield from "../standardTextfield";

export default function Step4({ onNext, chatMessages, setChatMessages }) {
  const [error, setError] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [serverReplied, setServerReplied] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const token = localStorage.getItem("token");
  const messagesEndRef = useRef(null);

  // Function to fetch data from the server
  const fetchData = async () => {
    try {
      const response = await axios.get('https://dev.retailhub.ai/api/v4/advisory/steps/current', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const stepData = response.data;
      setData(stepData);
      setLoading(false);
    } catch (error) {
      setError("Error fetching data");
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);

  // Scroll to bottom when chatMessages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages]);

  // Function to handle sending a new message
  const handleAddMessage = async () => {
    if (newMessage.trim() !== "") {
      setChatMessages(prevMessages => [
        ...prevMessages,
        { id: prevMessages.length, text: newMessage, sender: "You" }
      ]);

      setIsTyping(true);

      try {
        const payload = { message: newMessage };
        const response = await axios.post(
          'https://dev.retailhub.ai/api/v4/advisory/message',
          payload,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        setTimeout(() => {
          if (response.data && response.data.answer) {
            setChatMessages(prevMessages => [
              ...prevMessages,
              { id: prevMessages.length, text: response.data.answer, sender: "Server" }
            ]);
            setServerReplied(true);
          }
          setIsTyping(false);
        }, 1000);

      } catch (error) {
        console.error("Error sending message:", error);
        setError("Error sending message");
        setIsTyping(false);
      }

      setNewMessage("");
    }
  };

  const handleNewMessage = (event) => {
    setNewMessage(event.target.value);
  };

  const handleGoToEvaluation = () => {
    onNext(6);
  };

  // Handle click on "Restart", go to Step 1
  const handleRestart = () => {
    onNext(1);
  };

  // Function to handle back button click
  const handleBackToStep1 = async () => {
    try {
      setIsTyping(true); // Disable input while processing      
      onNext(1);
    } catch (error) {
      window.location.reload();
      console.error('Error changing to step 1:', error);
      //setError('Error changing step.');
      window.location.reload();
    } finally {
      setIsTyping(false); // Re-enable input
    }
  };

  return (
    <div className="flex flex-col h-full w-full">
      {/* Chat Container */}
      <div className="flex flex-col flex-grow h-0">
        {/* Chat Messages Area */}
        <div className="flex flex-col flex-grow overflow-y-auto px-5">
          {chatMessages.map((m, index) => (
            <div
              key={`${m.sender}-${index}`}
              className={`flex flex-col gap-1 ${
                m.sender === "You" ? "items-end" : "items-start"
              }`}
            >
              <span className="font-semibold">{m.sender}</span>
              <div className="p-2 bg-gray-800 w-full rounded-2xl max-w-[80%] whitespace-pre-wrap">
                {m.sender === "Server" ? (
                  <ReactMarkdown>{m.text}</ReactMarkdown>
                ) : (
                  <span>{m.text}</span>
                )}
              </div>
            </div>
          ))}

          {/* Step Actions */}
          <div className="p-5 bg-gray-800 rounded-2xl mt-4">
            <span className="font-semibold text-white">
              Do you want to proceed with the evaluation questionnaire or start over?
            </span>
            <div className="flex gap-2 mt-4">
              <Button variant="contained" sx={{backgroundColor: "rgb(var(--global-color-primary))", color: "#000"}} onClick={handleGoToEvaluation}>
                Go to Evaluation Questionnaire
              </Button>
              <Button variant="outlined" sx={{borderColor: "rgb(var(--global-color-primary))", color: "#000"}} onClick={handleRestart}>
                Restart
              </Button>
            </div>
          </div>

          {/* Typing Indicator */}
          {isTyping && (
            <div className="flex flex-col gap-1 items-start mt-4">
              <span className="font-semibold">Server</span>
              <div className="p-2 bg-gray-800 rounded-2xl max-w-[80%] flex flex-col">
                <span>Typing...</span>
              </div>
            </div>
          )}

          {/* Scroll Anchor */}
          <div ref={messagesEndRef} />
        </div>

        {/* Input Area */}
        <div className="p-5 w-full bg-black flex items-center gap-2">
          {/* Back to Step 1 Button */}
          <Button
            sx={{
              backgroundColor: "rgb(var(--global-color-primary))",
              color: "#000 !important",
              '&:hover': {
                backgroundColor: "rgb(var(--global-color-primary))",
              }
            }}
            onClick={handleBackToStep1}
            disabled={isTyping}
          >
            Restart
          </Button>

          <StandardTextfield
            variant="outlined"
            multiline={true}
            value={newMessage}
            placeholder="Write here..."
            onChange={handleNewMessage}
            disabled={isTyping}
            style={{ flexGrow: 1 }}
          />

          <Button
            variant="contained"
            sx={{
              backgroundColor: "rgb(var(--global-color-primary))",
              color: "black",
              minWidth: '48px',
              minHeight: '48px',
              '&:hover': {
                backgroundColor: "rgb(var(--global-color-primary))",
              }
            }}
            onClick={handleAddMessage}
            disabled={isTyping}
          >
            <SendIcon />
          </Button>
        </div>
      </div>

      {/* Display error */}
      {error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  );
}

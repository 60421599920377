import * as React from 'react';
import { Dialog, DialogActions, DialogContent} from '@mui/material';
import StandardButton from './standardButton';
import StandardTextfield from './standardTextfield';
import axios from 'axios';

export default function PromptDialog({name, interviewee_survey_id}) {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, field) => {
    switch (field) {
      case 'message':
        setMessage(event.target.value)
        break;
      default:
        setMessage(event.target.value)
        break;
    }
  }

  const promptInterviewee = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
      const url = `https://dev.retailhub.ai/api/v4/advisory/interviewee-survey/${interviewee_survey_id}/remind`;

      const response = await axios.post(url, 
        { 
          message: message
        },
        {headers: { Authorization: `Bearer ${token}` }}
      );

      console.log(response)
    } catch (err) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setOpen(false);
    }
  }

  return (
    <React.Fragment>
      <StandardButton 
        text="Sollecita"
        width="150px" 
        variant="filled"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: "16px !important",
            overflow: 'hidden',
            backgroundColor: "#191919"
          }
        }}
      >
        <DialogContent sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919"}}>
          <div className='flex flex-col gap-10 justify-center items-center w-full'>
            <div className='flex flex-col gap-2 justify-center items-center w-full'>
              <h1 className="text-xl font-bold w-full">Confermi di voler inviare una mail di sollecito a { name || "Missing"}?</h1>
              <span className="text-white w-full">Sondaggio del 24/07/1982 relativo alle startup Klarna, StartupOne, RetailMaster</span>
            </div>
            <StandardTextfield 
              label='Messaggio personalizzato' 
              value={message} 
              multiline={true}
              minRows={4}
              onChange={(e) => {handleChange(e, 'message')}} 
            />
          </div>
        </DialogContent>
        <DialogActions sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919", paddingTop: "40px", justifyContent: 'center', gap: '50px'}}>
          <StandardButton variant='outlined' onClick={handleClose} text="Annulla" width={"300px"} type={"secondary"}/>
          <StandardButton onClick={() => {promptInterviewee()}} autoFocus={true} text="Invia" width={"300px"}/>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
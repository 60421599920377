import * as React from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

export default function StandardSelect({label, items, value, onChange, multiple}) {
  return (  
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label" sx={{color: "white"}}>{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={onChange}
        multiple={!!multiple ? multiple : false}
        sx={{
          borderColor: "white",
          '& .MuiSelect-root': {
            '& fieldset': {
              borderColor: '#fff',
              fontFamily: 'Urbanist, sans-serif',
            },
            '&:hover fieldset': {
              borderColor: '#fff', 
            },
            '&.Mui-focused fieldset': {
              borderColor: '#fff', 
            },
          },
          '& .MuiOutlinedInput-input': {
            color: '#fff', 
            fontFamily: 'Urbanist, sans-serif',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            color: '#fff', 
            borderColor: "white",
            fontFamily: 'Urbanist, sans-serif',
          },
          '& .MuiInputLabel-root': {
            color: '#fff',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#fff',
          },
          '& .MuiSvgIcon-root-MuiSelect-icon': {
            fill: "white"
          }
        }}
      >
        {
          items.map((r) => 
            <MenuItem value={r.id} key={r.id}>{r.name}</MenuItem>
          )
        }
      </Select>
    </FormControl>
  )
}

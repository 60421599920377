import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import ReactMarkdown from 'react-markdown';
import SendIcon from '@mui/icons-material/Send';
import StandardTextfield from "../standardTextfield";

export default function Step3({ onNext, chatMessages, setChatMessages, innovations, message }) {
  const [error, setError] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false); // Cambiato da true a false all'inizio
  const [serverReplied, setServerReplied] = useState(false);
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const messagesEndRef = useRef(null);
  const [nextMessage, setNextMessage] = useState(false);

  const token = localStorage.getItem("token");

  // Funzione per inviare il messaggio sul processo di valutazione
  const sendEvaluationProcessMessage = async () => {
    try {
      setIsTyping(true); // Mostra l'effetto typing mentre invii il messaggio
      const payload = {
        message: "tell me more about the evaluation process"
      };

      const response = await axios.post(
        'https://dev.retailhub.ai/api/v4/advisory/message',
        payload,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data && response.data.answer) {
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { id: prevMessages.length, text: response.data.answer, sender: "Server" },
        ]);
        setServerReplied(true);
      }

      // Dopo la risposta del server, disabilita typing e mostra i pulsanti
      setTimeout(() => {
        setIsTyping(false);
        setIsBoxVisible(true); // Mostra i pulsanti solo dopo la risposta
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 1000); // Ritardo per simulare il typing

    } catch (error) {
      setError('Error sending message');
      setIsTyping(false);
    }
  };

  useEffect(() => {
    if (message) {
      // Se `message` è true, invia il messaggio automatico
      sendEvaluationProcessMessage();
    } else {
      // Se `message` è false, salta il typing e mostra direttamente i pulsanti
      setIsTyping(false); // Assicurati che l'effetto typing sia disabilitato
      setIsBoxVisible(true); // Mostra i pulsanti
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [message]);

  // Funzione per inviare i nomi delle startup selezionate e settare il `nextMessage`
  const handleSendStartupsMessage = async () => {
    try {
      // Nascondi il blocco dei pulsanti
      setIsBoxVisible(false);

      // Imposta `nextMessage` su true
      setNextMessage(true);

      // Passa allo step 5
      onNext(5, innovations, nextMessage); // Puoi passare anche le startup se necessario
    } catch (error) {
      console.error("Error sending message:", error);
      setError("Error sending message");
    }
  };

  // Funzione per inviare nuovi messaggi
  const handleAddMessage = async () => {
    if (newMessage.trim() !== "") {
      setChatMessages(prevMessages => [
        ...prevMessages,
        { id: prevMessages.length, text: newMessage, sender: "You" }
      ]);

      setIsTyping(true);

      try {
        const payload = { message: newMessage };
        const response = await axios.post(
          "https://dev.retailhub.ai/api/v4/advisory/message",
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        setTimeout(() => {
          if (response.data && response.data.answer) {
            setChatMessages(prevMessages => [
              ...prevMessages,
              { id: prevMessages.length, text: response.data.answer, sender: "Server" }
            ]);
            setServerReplied(true);
          }
          setIsTyping(false);
        }, 1000);

      } catch (error) {
        console.error("Error sending message:", error);
        setError("Error sending message");
        setIsTyping(false);
      }

      setNewMessage("");
    }
  };

  const handleNewMessage = (event) => {
    setNewMessage(event.target.value);
  };

  // Funzione per il click su "No", vai allo Step 5
  const handleSecondNoClick = () => {
    onNext(5);
  };

  // Funzione per andare al questionario di valutazione
  const handleGoToEvaluation = () => {
    onNext(6);
  };

  // Funzione per tornare allo Step 1
  const handleBackToStep1 = async () => {
    try {
      setIsTyping(true); // Disabilita l'input durante l'elaborazione
      onNext(1);
    } catch (error) {
      window.location.reload();
      console.error('Error changing to step 1:', error);
    } finally {
      setIsTyping(false); // Riabilita l'input
    }
  };

  return (
    <div className="flex flex-col h-full w-full">
      {/* Chat Container */}
      <div className="flex flex-col flex-grow h-0">
        {/* Chat Messages Area */}
        <div className="flex flex-col flex-grow overflow-y-auto px-5">
          {chatMessages.map((m, index) => (
            <div
              key={`${m.sender}-${index}`}
              className={`flex flex-col gap-1 ${
                m.sender === "You" ? "items-end" : "items-start"
              }`}
            >
              <span className="font-semibold">{m.sender}</span>
              <div className="p-2 bg-gray-800 w-full rounded-2xl max-w-[80%] whitespace-pre-wrap">
                {m.sender === "Server" ? (
                  <ReactMarkdown>{m.text}</ReactMarkdown>
                ) : (
                  <span>{m.text}</span>
                )}
              </div>
            </div>
          ))}

          {/* Effetto typing */}
          {isTyping && (
            <div className="flex flex-col gap-1 items-start mt-4">
              <span className="font-semibold">Server</span>
              <div className="p-2 bg-gray-800 rounded-2xl max-w-[80%] flex flex-col">
                <span>Typing...</span>
              </div>
            </div>
          )}

          {/* Second Question - Viene mostrata solo dopo che il messaggio automatico è stato risposto */}
          {!isTyping && isBoxVisible && (
            <div className="p-5 bg-black-l rounded-2xl mt-4 max-w-[100%]">
              <span className="font-semibold text-white">
                Perfect! Do you know what the following startups are about? Do you want to talk about them?
              </span>
              <div className="flex gap-2 mt-4">
                <Button variant="contained" sx={{backgroundColor: "rgb(var(--global-color-primary))", color: "#000"}} onClick={handleSendStartupsMessage}>
                  Yes
                </Button>
                <Button variant="outlined" sx={{borderColor: "rgb(var(--global-color-primary))", color: "#000"}} onClick={handleSecondNoClick}>
                  No, go to questionairre
                </Button>
              </div>
            </div>
          )}

          {/* Scroll Anchor */}
          <div ref={messagesEndRef} />
        </div>

        {/* Input Area */}
        <div className="p-5 w-full bg-black flex items-center gap-2">
          {/* Back to Step 1 Button */}
          <Button
            sx={{
              backgroundColor: "rgb(var(--global-color-primary))",
              color: "#000 !important",
              '&:hover': {
                backgroundColor: "rgb(var(--global-color-primary))",
              }
            }}
            onClick={handleBackToStep1}
            disabled={isTyping}
          >
            Restart
          </Button>

          <StandardTextfield
            variant="outlined"
            multiline={true}
            value={newMessage}
            placeholder="Write here..."
            onChange={handleNewMessage}
            disabled={isTyping}
            style={{ flexGrow: 1 }}
          />

          <Button
            variant="contained"
            sx={{
              backgroundColor: "rgb(var(--global-color-primary))",
              color: "black",
              minWidth: '48px',
              minHeight: '48px',
              '&:hover': {
                backgroundColor: "rgb(var(--global-color-primary))",
              }
            }}
            onClick={handleAddMessage}
            disabled={isTyping}
          >
            <SendIcon />
          </Button>
        </div>
      </div>

      {/* Display error */}
      {error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  );
}

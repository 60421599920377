import React, { useEffect } from "react";
import NewDiscoveryChip from "../components/newDiscoveryChip";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SearchIcon from "@mui/icons-material/Search"
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import PromptDialog from "../components/promptDialog";
import DeleteSurveyAnswersDialog from "../components/deleteSurveyAnswersDialog";
import SurveyStartupRankingWidget from "../components/widgets/surveyStartupRanking";
import SurveyQuestionsChartWidget from "../components/widgets/surveyQuestionsChart";
import SurveyPointChartRankingWidget from "../components/widgets/surveyPointChartRanking";
import InviteCollaboratorsDialog from "../components/inviteCollaboratorsDialog";
import { Button } from "@mui/material";
import FlexibleDataFetcher from "../components/flexibileDataFetcher";
import axios from "axios";

function formatDateTime(isoString) {
  const date = new Date(isoString);

  // Format the date part (dd/mm/yyyy)
  const formattedDate = date.toLocaleDateString('en-GB'); // 'en-GB' gives dd/mm/yyyy format

  // Format the time part (HH:mm)
  const formattedTime = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false // 24-hour format
  });

  return `${formattedDate} ${formattedTime}`;
}

export default function Survey() {
  const [surveys, setSurveysList] = useState([]);
  const [selectedSurveyCompletion, setSurveyCompletion] = useState({})
  const [startups, setStartupList] = useState([]);
  const [firstWidget, setFirstWidget] = useState({})
  const [secondWidget, setSecondWidget] = useState({})
  const [thirdWidget, setThirdWidget] = useState({})
  const [fourthWidget, setFourthWidget] = useState([])
  const [selectedStartupCompletion, setSelectedStartupCompletion] = useState({})
  const [loading, setLoading] = useState(true);
  const [completionLoading, setCompletionLoading] = useState(true);
  const [startupLoading, setStartupLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState('Sent')
  const [searchOpen, setSearchOpen] = useState(false)
  const [aiOpen, setAiOpen] = useState(false)
  const companyId = localStorage.getItem('companyId');
  const [surveyInterviewees, setSurveyInterviewees] = useState([])
  const [surveyInterviewees2, setSurveyInterviewees2] = useState([])
  const [selectedStartup, setSelectedStartup] = useState({})
  const [selectedSurvey, setSelectedSurvey] = useState({})
  const [selectedSurveyId, setSelectedSurveyId] = useState(0)
  const [responseInterviewees, setResponseInterviewees] = useState([])
  const [chatOpen, setChatOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [loadingChat, setLoadingChat] = useState(false);
  let surveyId = 0

  // Handle data fetch success for startups
  const handleSurveyIntervieweesSuccess = (data) => {
    if (data && data.data) {
      setSurveyInterviewees(data.data)
      setSurveyInterviewees2(data.data)      
    }
    setLoading(false);
  };

  // Start Chat
  const startChat = async (innovationId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        'https://dev.retailhub.ai/api/v4/advisory/chat-statistics-start',
        { innovation_id: innovationId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setChatMessages([]); // Resetta i messaggi della chat ogni volta che si avvia
    } catch (err) {
      console.error('Errore durante l\'avvio della chat', err);
      setError('Errore durante l\'avvio della chat');
    }
  };

  // Chat Messages

  const sendMessage = async () => {
    if (!messageInput.trim()) return;
  
    setChatMessages(prev => [...prev, { sender: 'You', text: messageInput }]);
    const currentMessage = messageInput;
    setMessageInput('');
    setLoadingChat(true);
  
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `https://dev.retailhub.ai/api/v4/advisory/message?type=statistics`,
        { innovation_id: selectedStartup.innovation_id, message: currentMessage },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      const serverMessage = response.data.answer;
      if (serverMessage) {
        setChatMessages(prev => [...prev, { sender: 'Server', text: serverMessage }]);
      } else {
        console.error('Messaggio del server non disponibile:', response.data);
        setError('Messaggio del server non disponibile');
      }
    } catch (err) {
      console.error('Errore durante l\'invio del messaggio', err);
      setError('Errore durante l\'invio del messaggio');
    } finally {
      setLoadingChat(false);
    }
  };

  const handleAiOpen = () => {
    setAiOpen(!aiOpen);
    setSearchOpen(false);
    setChatOpen(true);
    startChat(selectedStartup.innovation_id); // Avvia la chat con l'ID della startup selezionata
  };
  
  
  

  // Handle data fetch error
  const handleSurveyIntervieweesError = (err) => {
    setError(err.message || 'Failed to fetch data');
    setLoading(false);
  };

  // Handle data fetch success for startups
  const handleFirstWidgetFetchSuccess = (data) => {
    if (data) {
      setFirstWidget(data)
    }
    setLoading(false);
  };

  // Handle data fetch error
  const handleFirstWidgetDataFetchError = (err) => {
    setError(err.message || 'Failed to fetch data');
    setLoading(false);
  };
  
  // Handle data fetch success for startups
  const handleStartupListSuccess = (data) => {
    if (data) {
      if(data.length > 0) {
        let filteredStartups = []
        for (let i = 0; i < data.length; i++) {
          if(!filteredStartups.find(fs => fs.innovation_id === data[i].innovation_id)) {
            filteredStartups.push({...data[i], selected: filteredStartups.filter(fs => fs.selected).length === 0 ? true : false})
          }
          if(i === 0) {
            setSelectedStartup(data[0])
          }
        }
        setStartupList(filteredStartups)
      }
    }
    setLoading(false);
  };

  // Handle data fetch error
  const handleStartupListError = (err) => {
    setError(err.message || 'Failed to fetch data');
    setLoading(false);
  };
  
  // Handle data fetch success for startups
  const handleSurveysListSuccess = (data) => {
    if (data) {
      if(data.length > 0) {
        let filteredSurveys = []
        for (let i = 0; i < data.length; i++) {
          filteredSurveys.push({
            ...data[i], 
            formattedDate: formatDateTime(data[i].created_timestamp), 
            selected: filteredSurveys.filter(fs => fs.selected).length === 0 ? true : false
          })          
          if(i === 0) {
            setSelectedSurvey(data[0])
            setSelectedSurveyId(data[0].id)
            surveyId = data[0].id
          }
        }
        setSurveysList(filteredSurveys)
      }
    }
    setLoading(false);
    fetchData()
  };

  // Handle data fetch error
  const handleDataFetchError = (err) => {
    setError(err.message || 'Failed to fetch data');
    setLoading(false);
  };

  const handleSearchOpen = () => {
    setSearchOpen(!searchOpen)
    setAiOpen(false)
  }
  
  const handleTabSelection = (tab) => {
    setSelectedTab(tab)
    if(tab === "Startup") {
      fetchStartupData()
    } else {
      fetchData()
    }
  }

  const handleSelectionSurvey = async (event, id) => {
    setSelectedSurvey(surveys.find(s => s.id === id))
    setSelectedSurveyId(id)
    surveyId = id
    let newSurveys = []
    for (let i = 0; i < surveys.length; i++) {
      if(surveys[i].selected && surveys[i].id !== id){
        newSurveys.push({...surveys[i], selected: false})
      } else if (surveys[i].id === id ) {
        newSurveys.push({...surveys[i], selected: true})
      } else {
        newSurveys.push(surveys[i])
      }
    }
    setSurveysList(newSurveys)
    await fetchData()
  }
  
  const handleSelectionStartup = async (event, id) => {
    setSelectedStartup(startups.find(s => s.innovation_id === id))
    await startChat(startups.innovation_id);
    let newStartups = []
    for (let i = 0; i < startups.length; i++) {
      if(startups[i].selected && startups[i].innovation_id !== id){
        newStartups.push({...startups[i], selected: false})
      } else if (startups[i].innovation_id === id ) {
        newStartups.push({...startups[i], selected: true})
      } else {
        newStartups.push(startups[i])
      }
    }
    setStartupList(newStartups)
    await fetchStartupData()
  }

  const fetchData = async () => {
    setCompletionLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
  
      const url = `https://dev.retailhub.ai/api/v4/advisory/results/completion`;
      const response = await axios.get(url, {
        params: { survey_id: surveyId !== 0 ? surveyId : selectedSurveyId },
        headers: { Authorization: `Bearer ${token}` }
      });
  
      const intervieweesUrl = `https://dev.retailhub.ai/api/v4/advisory/interviewees`;
      const responseIntervieweesResult = await axios.get(intervieweesUrl, {
        params: { 
          survey_id: surveyId !== 0 ? surveyId : selectedSurveyId 
        },
        headers: { Authorization: `Bearer ${token}` }        
      });
  
      // Imposta gli stati per surveyInterviewees e responseInterviewees
      setSurveyInterviewees(responseIntervieweesResult.data.data);
      setResponseInterviewees(response.data.interviewees);  // Modifica qui per salvare gli intervistati
  
      setSurveyCompletion(response.data);
    } catch (err) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setCompletionLoading(false);
    }
  }
  
  const fetchStartupData = async () => {
    setStartupLoading(true);
    try {
      const token = localStorage.getItem('token');
      const companyIdToken = localStorage.getItem('companyId');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
      const url = `https://dev.retailhub.ai/api/v4/advisory/results/by-innovation`;
      const widgetsUrl = `https://dev.retailhub.ai/api/v4/advisory/results/by-absolute`;
      const histogramUrl = `https://dev.retailhub.ai/api/v4/advisory/results/histograms`;

      let selectedStartupIds = [selectedStartup.innovation_id]

      const response = await axios.get(url, {
        params: { 
          company_id: companyIdToken,
          innovation_id: selectedStartup.innovation_id
        },
        headers: { Authorization: `Bearer ${token}` }
      });

      const firstWidgetData = await axios.get(widgetsUrl, {
        params: { 
          company_id: companyIdToken,
          innovations_ids: selectedStartupIds,
          widget_id: 1 
        },
        headers: { Authorization: `Bearer ${token}` }
      });      

      const secondWidgetData = await axios.get(widgetsUrl, {
        params: { 
          company_id: companyIdToken,
          innovations_ids: selectedStartupIds,
          widget_id: 2
        },
        headers: { Authorization: `Bearer ${token}` }
      });
      
      const thirdWidgetData = await axios.get(widgetsUrl, {
        params: { 
          company_id: companyIdToken,
          innovations_ids: selectedStartupIds,
          widget_id: 3
        },
        headers: { Authorization: `Bearer ${token}` }
      });
      
      const fourthWidgetData = await axios.get(histogramUrl, {
        params: { 
          company_id: companyIdToken,
          innovation_id: selectedStartup.innovation_id
        },
        headers: { Authorization: `Bearer ${token}` }
      });

      setFirstWidget(firstWidgetData.data)
      setSecondWidget(secondWidgetData.data)
      setThirdWidget(thirdWidgetData.data)
      setFourthWidget(fourthWidgetData.data.data)

      setSelectedStartupCompletion(response.data.data)
    } catch (err) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setStartupLoading(false);
    }
  }

  const handleAiClose = () => {
    setAiOpen(false);
    setChatOpen(false);
    setChatMessages([]); // Resetta i messaggi della chat
  };
  

  return (
    <div className="p-5 flex justify-center items-center h-full max-h-full">
      <div className="p-5 flex items-center justify-center w-full h-full max-h-full bg-[#191919] rounded-2xl">
        <div className="flex flex-col gap-5 max-w-full max-h-full w-full h-full">
          <NewDiscoveryChip />
          <div className="flex justify-between items-center w-full">
            <h1 className="text-6xl font-medium">Survey</h1>
            <Button 
              href="/survey/chat" 
              sx={{
                fontFamily: 'Urbanist, sans-serif',
                width: "300px", 
                backgroundColor: 'rgb(var(--global-color-primary))',
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'none'
              }} 
            >Start new survey</Button>
          </div>
          <div className="flex gap-4 items-center">
            <NotificationsActiveIcon sx={{color: "rgb(var(--global-color-primary))"}}/>
            <span>Advisory Program allows to compare a series of startups that match your search in order to carry out a complete evaluation and generate  reports</span>
          </div>
          <div className="flex gap-2">
            <div 
              className={`flex cursor-pointer ${selectedTab === 'Sent' ? 'text-[rgb(var(--global-color-primary))] border-b border-solid border-[rgb(var(--global-color-primary))]' : ""}`}
              onClick={() => handleTabSelection('Sent')}
            >
              Sent
            </div>
            <div 
              className={`flex cursor-pointer ${selectedTab === 'Startup' ? 'text-[rgb(var(--global-color-primary))] border-b border-solid border-[rgb(var(--global-color-primary))]' : ""}`}
              onClick={() => handleTabSelection('Startup')}
            >
              Startup
            </div>
          </div>
          <div className="flex gap-5 w-full max-w-full h-full max-h-[700px]">
            <div className="flex gap-5 w-full max-w-full h-full max-h-full">
              <div className={`h-full flex flex-col items-center bg-black p-5 rounded-2xl ${searchOpen ? 'w-[400px] justify-start' : 'w-fit justify-center'}`}>
                {
                  !searchOpen &&
                  <SearchIcon onClick={handleSearchOpen} sx={{cursor: 'pointer'}}/>
                }
                {
                  searchOpen &&
                  <div className="flex flex-col gap-2 w-full">
                    <div className="flex justify-end w-full">
                      <CloseIcon onClick={handleSearchOpen} sx={{cursor: 'pointer'}} />
                    </div>
                    <div className="flex flex-col gap-2 w-full h-full overflow-y-auto max-h-[620px]">
                      {
                        loading &&
                        <span className="text-sm">Loading Surveys...</span>
                      }
                      {
                        !loading &&
                        selectedTab === 'Startup' &&
                        startups.map(s =>
                          <div 
                            key={s.id} 
                            onClick={(e) => {handleSelectionStartup(e, s.innovation_id)}}
                            className={`p-4 flex flex-col gap-1 w-full rounded-2xl font-semibold cursor-pointer ${ s.selected ? "bg-[rgb(var(--global-color-primary))] text-black" : "bg-black hover:bg-[rgb(255,255,255,.1)]" }`}
                          >
                            <span className="text-sm">{s.name}</span>
                          </div>
                        )
                      }
                      {
                        !loading &&
                        selectedTab === 'Sent' &&
                        surveys.map(s =>
                          <div 
                            key={s.id} 
                            onClick={(e) => {handleSelectionSurvey(e, s.id)}}
                            className={`p-4 flex flex-col gap-1 w-full rounded-2xl font-semibold cursor-pointer ${ s.selected ? "bg-[rgb(var(--global-color-primary))] text-black" : "bg-black hover:bg-[rgb(255,255,255,.1)]" }`}
                          >
                            <span className="text-sm">Survey {s.formattedDate}</span>
                            <div className="flex gap-1">
                              {
                                s.innovations.map(i =>
                                  <span className="text-xs" key={i.id}>{i.name}, </span>
                                )
                              }
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                }
              </div>
              <div 
                className={`flex flex-col gap-5 w-full 
                  ${
                    selectedTab === "Startup" && (aiOpen || searchOpen) ? 
                    "max-w-[calc(100%-504px)]" : 
                    selectedTab === 'Sent' && searchOpen ?
                    "max-w-[calc(100%-440px)]" :
                    selectedTab === "Startup" && (!aiOpen || !searchOpen) ? 
                    "max-w-[calc(100%-168px)]" : 
                    "max-w-[calc(100%-104px)]"
                  } 
                  h-full max-h-full`
                }
              >
                {
                  selectedTab === "Startup" &&
                  <div className="flex flex-wrap justify-between bg-black p-5 rounded-2xl w-full max-w-full">
                    <div className="flex gap-5 items-center">
                      <img src={"https://dev.retailhub.ai/assets/" + selectedStartup.logo_url || ""} alt="logo" className="w-20 h-20 object-cover rounded-2xl" />
                      <div className="flex flex-col gap-1">
                        <h2 className="text-3xl">{selectedStartup.name}</h2>
                        <span>Direct payments, pay after delivery options and installment plans provider.</span>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <div className="flex flex-col justify-center items-center gap-1">
                        <span className="text-sm">Nr. Campaigns</span>
                        <span className="text-sm">{selectedStartupCompletion.total_campaigns}</span>
                      </div>
                      <div className="flex flex-col justify-center items-center gap-1">
                        <span className="text-sm">Surveys Completed</span>
                        <span className="text-sm">{selectedStartupCompletion.total_completed_surveys} / {selectedStartupCompletion.total_sent_surveys}</span>
                      </div>
                      <div className="flex flex-col justify-center items-center gap-1">
                        <span className="text-sm">Status</span>
                        <span className="text-sm">{selectedStartupCompletion.status}</span>
                      </div>
                    </div>
                  </div>
                }
                {
                  selectedTab === "Startup" &&
                  <div className="flex flex-col gap-5 items-center h-full max-h-full max-w-full overflow-hidden overflow-y-auto">
                    {
                      startupLoading ?
                      (<span>Loading datas...</span>):
                      (
                        <>
                          <SurveyStartupRankingWidget 
                            sliderValues={firstWidget.data} 
                            labels={firstWidget.projections.sort((a, b) => {
                              if (a.projection_order < b.projection_order) {
                                return -1;
                              }
                              if (a.projection_order > b.projection_order) {
                                return 1;
                              }
                              return 0;
                            }).map(p => {return p.projection_name})} 
                          />
                          <SurveyPointChartRankingWidget 
                            labels={
                              !!secondWidget.projections &&
                              !!thirdWidget.projections ?
                              [
                                ...secondWidget.projections.map(sw => {return sw.projection_name}),
                                ...thirdWidget.projections.map(tw => {return tw.projection_name})
                              ] : []
                            }
                            data={!!secondWidget.data && !!thirdWidget.data ? [...secondWidget.data, ...thirdWidget.data] : []}
                            startupName={selectedStartup.name || ""}
                          />
                          <SurveyQuestionsChartWidget data={fourthWidget} />
                        </>
                      )
                    }
                  </div>
                }
                {
                  !completionLoading &&
                  selectedTab === 'Sent' &&
                  <div className="flex flex-col gap-10 justify-between w-full max-w-full h-full max-h-full overflow-hidden overflow-y-auto bg-black p-5 rounded-2xl">
                    <div className="flex flex-col items-center gap-10 w-full">
                      <div className="flex flex-col items-center gap-2 w-full">
                        <h2 className="text-3xl">Tasso di completamento</h2>
                        <span className="text-7xl font-bold">{selectedSurveyCompletion.completed_surveys} / {selectedSurveyCompletion.total_surveys}</span>
                      </div>
                      {
                        !!selectedSurveyCompletion.interviewees &&
                        selectedSurveyCompletion.interviewees.filter(i => !i.completed_survey).length > 0 &&
                        <div className="flex flex-col gap-2 w-full">
                          <span className="font-semibold text-lg">Devono ancora rispondere:</span>
                          <div className="flex flex-col gap-1 w-full max-h-[250px] overflow-hidden overflow-y-auto">
                            {
                              selectedSurveyCompletion.interviewees.filter(i => !i.completed_survey).map(na =>
                                <div key={na.interviewee_id} className="flex items-center justify-between w-full py-1 border-b border-[rgb(255,255,255,.3)]">
                                  <span>{na.first_name} {na.last_name}</span>
                                  <PromptDialog name={`${na.first_name} ${na.last_name}`} interviewee_survey_id={na.interviewee_survey_id} />
                                </div>
                              )
                            }                     
                          </div>
                        </div>
                      }
                    </div>
                    <div className="flex items-center justify-center gap-5 w-full">
                      <DeleteSurveyAnswersDialog interviewees={selectedSurveyCompletion.interviewees}/>
                      <InviteCollaboratorsDialog 
                        users={responseInterviewees.length === 0 
                          ? surveyInterviewees2 
                          : surveyInterviewees2.filter(i => responseInterviewees.find(ri => ri.email != i.email))
                        }
                        survey_id={selectedSurvey.id} 
                        startups={surveys.find(s => Number(s.id) === selectedSurvey.id)?.innovations}
                      />
                    </div>
                    {
                      !!selectedSurveyCompletion.interviewees &&
                      selectedSurveyCompletion.interviewees.filter(i => i.completed_survey).length > 0 &&
                      <div className="flex flex-col gap-2 w-full">
                        <span className="font-semibold text-lg">Hanno risposto:</span>
                        <div className="flex flex-col gap-1 w-full max-h-[250px] overflow-hidden overflow-y-auto">
                          {
                            selectedSurveyCompletion.interviewees.filter(i => i.completed_survey).map(na =>
                              <div key={na.interviewee_id} className="flex items-center justify-between w-full py-1 border-b border-[rgb(255,255,255,.3)]">
                                <span>{na.first_name} {na.last_name}</span>
                              </div>
                            )
                          }                     
                        </div>
                      </div>
                    }
                  </div>
                }
                {
                  completionLoading &&
                  selectedTab === "Sent" &&
                  <div className="flex justify-center items-center w-full max-w-full h-full max-h-full bg-black p-5 rounded-2xl">
                    <span className="text-sm">Loading survey...</span>
                  </div>
                }
              </div>
              {
                selectedTab === 'Startup' &&
                <div className={`h-full flex items-center bg-black p-5 rounded-2xl ${aiOpen ? 'w-[400px]' : 'w-fit'}`}>
              {
                !aiOpen &&
                <FaceRetouchingNaturalIcon onClick={handleAiOpen} sx={{cursor: 'pointer'}}/>
              }
              {
                aiOpen &&
                <div className="flex flex-col gap-2 w-full h-full">
                  <div className="flex justify-end w-full">
                    <CloseIcon onClick={handleAiClose} sx={{cursor: 'pointer'}} />
                  </div>
                  {/* Chat Messages Area */}
                  <div className="flex flex-col flex-grow overflow-y-auto px-5">
                    {chatMessages.map((m, index) => (
                      <div
                        key={`${m.sender}-${index}`}
                        className={`flex flex-col gap-1 ${
                          m.sender === "You" ? "items-end" : "items-start"
                        }`}
                      >
                        <span className="font-semibold">{m.sender}</span>
                        <div className="p-2 bg-gray-800 w-full rounded-2xl max-w-[80%] whitespace-pre-wrap msgClass">
                          <span>{m.text}</span>
                        </div>
                      </div>
                    ))}
                    {loadingChat && (
                      <div className="flex items-center">
                        <span>Sto pensando...</span>
                      </div>
                    )}
                  </div>
                  {/* Input per i messaggi */}
                  <div className="flex items-center p-2">
                    <input
                      type="text"
                      className="flex-grow p-2 bg-gray-700 rounded-l-2xl focus:outline-none"
                      value={messageInput}
                      onChange={(e) => setMessageInput(e.target.value)}
                      onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
                      placeholder="Scrivi un messaggio..."
                    />
                    <button
                      className="p-2 bg-blue-600 rounded-r-2xl"
                      onClick={sendMessage}
                      disabled={loadingChat}
                      style={{ backgroundColor: '#C5FF55', color: '#000' }}
                    >
                      Invia
                    </button>
                  </div>
                </div>
              }
            </div>

              }
            </div>
          </div>
        </div>
      </div>
      <FlexibleDataFetcher
        endpoint="/advisory/surveys"
        params={{ company_id: companyId }}
        onSuccess={handleSurveysListSuccess}
        onError={handleDataFetchError}
      />
      <FlexibleDataFetcher
        endpoint="/advisory/results/answers-count-by-innovation"
        params={{ company_id: Number(companyId) }}
        onSuccess={handleStartupListSuccess}
        onError={handleStartupListError}
      />
      <FlexibleDataFetcher
        endpoint="/advisory/results/by-absolute"
        params={{ 
          company_id: Number(companyId),
          innovations_ids: startups.length > 0 ? [startups[0].innovation_id] : [],
          widget_id: 1 
        }}
        onSuccess={handleFirstWidgetFetchSuccess}
        onError={handleFirstWidgetDataFetchError}
      />
      <FlexibleDataFetcher
        endpoint="/advisory/interviewees"
        params={{ 
          company_id: companyId
        }}
        onSuccess={handleSurveyIntervieweesSuccess}
        onError={handleSurveyIntervieweesError}
      />
    </div>
  )
}

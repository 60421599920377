import React from "react";

export default function AdvisoryHeatmapWidget({ heatmapData }) {
  if (!heatmapData) {
    return <div>Loading Heatmap...</div>;
  }

  const { projectionNames, heatmapValues } = heatmapData;

  // Prepara 'stup' (startups), includendo una prima voce vuota per l'angolo in alto a sinistra
  const stup = [
    { id: 0, name: '' },
    ...heatmapValues.map((row, index) => ({
      id: index + 1,
      name: row.innovation_description
    }))
  ];

  // Prepara 'heatmapColumns' (colonne)
  const heatmapColumns = projectionNames.map((projectionName, colIndex) => {
    return {
      id: colIndex,
      name: projectionName,
      value: heatmapValues.map(row => row.values[colIndex]) // Ottiene il valore per questa colonna da ogni riga
    };
  });

  return (
    <div className="flex p-5 gap-5 items-center bg-black rounded-2xl w-full h-max max-w-full">
      <div className="flex flex-col gap-2">
        {stup.map(s => 
          <div key={s.id} className="h-[48px] font-bold flex items-center">{s.name}</div>
        )}
      </div>
      <div className="flex gap-1 w-full max-w-full overflow-x-auto">
        {
          heatmapColumns.map(h => 
            <div className="flex flex-col gap-2 items-center justify-center w-full h-full" key={h.id}>
              <span className="w-min h-[48px] text-sm font-bold">{h.name}</span>
              {
                h.value.map((v, index) => 
                  <span 
                    key={index}
                    className={`w-full flex justify-center items-center p-5 rounded-xl font-semibold text-white h-[48px] 
                      ${ v !== null && v >= 0 && v < 2 ? 
                        "bg-[#B1A9D9]" : v >= 2 && v < 4 ? 
                        "bg-[#7D6FC8]" : v >= 4 && v < 6 ? 
                        "bg-[#1D1984]" : v >= 6 && v < 8 ?
                        "bg-[#190064]" : v >= 8 ?
                        "bg-[#000424]" : "bg-gray-300"
                      }
                    `}
                  >{v !== null ? v : '-'}</span>
                )
              }
            </div>
          )
        }
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';

function Auth() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
        // Send login request
        const response = await axios.post('https://dev.retailhub.ai/api/v4/login', {
            email: email,
            password: password
        });

        // Save the token, user ID, and company ID in localStorage
        const token = response.data.token;      
        const userId = response.data.user.id;
        const companyId = response.data.user.companies[0].id;

        // Store values in localStorage
        localStorage.setItem('token', token);

        /// !!! Da rimuovere
        //localStorage.setItem('token', 'authtoken');

        localStorage.setItem('userId', userId);
        localStorage.setItem('companyId', companyId);

        // Set success state and redirect to dashboard or desired route
        setSuccess(true);
        console.log('Login successful, token:', token, 'userId:', userId, 'companyId:', companyId);

        // Redirect to the home or any protected route
        navigate('/');
        
    } catch (err) {
        setError('Login failed. Please check your credentials.');
        console.error('Error:', err);
    }
  };


  return (
    <div className="bg-black-l p-5 flex flex-col gap-10 min-h-[90vh]">
    <Box
      component="form"
      onSubmit={handleLogin}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '166px',
        
      }}
    >
      
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>

      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        variant="outlined"
        margin="normal"
        fullWidth
        required
      />

      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        variant="outlined"
        margin="normal"
        fullWidth
        required
      />

      {error && (
        <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ width: '100%', mt: 2 }}>
          Login successful! Redirecting...
        </Alert>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        fullWidth
      >
        Login
      </Button>
    </Box>
    </div>
  );
}

export default Auth;

import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import StandardButton from './standardButton';
import StandardTextfield from './standardTextfield';
import StandardSelect from './standardSelect';
import axios from 'axios';

export default function NewAdvisoryDialog({ onAdvisoryAdded }) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startupsSelected, setStartupsSelected] = useState([]);
  const [startupsOptions, setStartupsOptions] = useState([]);
  const [loadingStartups, setLoadingStartups] = useState(true);
  const [error, setError] = useState(null);

  const companyId = localStorage.getItem('companyId');
  const token = localStorage.getItem('token'); // Se necessario

  useEffect(() => {
    // Funzione per recuperare le startup dall'API
    const fetchStartups = async () => {
      try {
        const response = await axios.get(`https://dev.retailhub.ai/api/v4/advisory/results/answers-count-by-innovation`, {
          params: {
            company_id: companyId
          },
          headers: {
            'Authorization': `Bearer ${token}` // Se necessario
          }
        });
        const startupsData = response.data;
        // Mappa i dati per adattarli al formato richiesto da StandardSelect
        const options = startupsData.map(startup => ({
          id: parseInt(startup.innovation_id),
          name: startup.name
        }));
        setStartupsOptions(options);
        setLoadingStartups(false);
      } catch (err) {
        console.error('Errore nel recupero delle startup:', err);
        setError('Errore nel recupero delle startup');
        setLoadingStartups(false);
      }
    };

    fetchStartups();
  }, [companyId, token]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // Resetta i campi del form
    setName("");
    setDescription("");
    setStartupsSelected([]);
  };

  const handleChange = (event, field) => {
    switch (field) {
      case 'name':
        setName(event.target.value);
        break;
      case 'description':
        setDescription(event.target.value);
        break;
      case 'startups':
        const selectedIds = event.target.value;
        console.log('Startups selezionate (IDs):', selectedIds);
        setStartupsSelected(selectedIds);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    // Prepara i dati per la richiesta POST
    const data = {
      company_id: parseInt(companyId),
      title: name,
      description: description,
      innovations_ids: startupsSelected.map(id => parseInt(id))
    };

    console.log('Dati inviati:', data);

    try {
      const response = await axios.post(
        'https://dev.retailhub.ai/api/v4/advisory/analyses',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Se necessario
          }
        }
      );
      console.log('Risposta dal server:', response.data);
      // Chiudi il dialog
      handleClose();
      // Chiama la funzione di callback per aggiornare la lista delle advisories
      if (onAdvisoryAdded) {
        onAdvisoryAdded();
      }
    } catch (err) {
      console.error('Errore durante l\'invio dei dati:', err);
      // Puoi gestire l'errore e mostrare un messaggio all'utente
    }
  };

  return (
    <React.Fragment>
      <StandardButton
        text="Add new advisory"
        width="300px"
        variant="filled"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: "16px !important",
            overflow: 'hidden',
            backgroundColor: "#191919"
          }
        }}
      >
        <DialogContent sx={{ fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919" }}>
          <div className='flex flex-col gap-10 justify-center items-center'>
            <h1 className="text-6xl">Add new advisory</h1>
            {loadingStartups ? (
              <p>Loading startups...</p>
            ) : error ? (
              <p style={{ color: 'red' }}>{error}</p>
            ) : (
              <div className='flex flex-col gap-4 w-full'>
                <div className='flex flex-wrap gap-4 lg:grid lg:grid-cols-2 w-full'>
                  <StandardTextfield label='Name' value={name} onChange={(e) => { handleChange(e, 'name') }} />
                  <StandardSelect
                    items={startupsOptions}
                    value={startupsSelected}
                    label={"Startups"}
                    multiple={true}
                    onChange={(e) => { handleChange(e, 'startups') }}
                    optionValue="id" // Specifica che l'opzione usa 'id' come valore
                    optionLabel="name" // Specifica che l'opzione usa 'name' come etichetta
                    id="Test"
                  />
                </div>
                <StandardTextfield label='Description' value={description} onChange={(e) => { handleChange(e, 'description') }} />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions sx={{ fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919", paddingTop: "40px", justifyContent: 'center', gap: '50px' }}>
          <StandardButton variant='outlined' onClick={handleClose} text="Cancel" width={"300px"} type={"secondary"} />
          <StandardButton onClick={handleSubmit} autoFocus={true} text="Submit" width={"300px"} />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './header';
import Sidebar from './sidebar';
import { Box } from '@mui/material';

const Layout = ({ children }) => {
  const location = useLocation();

  // Define the paths where Header and Sidebar should not be shown
  const hideHeaderAndSidebar = location.pathname === '/auth';

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Render Header only if not on Auth page */}
      {!hideHeaderAndSidebar && <Header />}

      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {/* Render Sidebar only if not on Auth page */}
        {!hideHeaderAndSidebar && <Sidebar />}

        {/* Main content */}
        <Box component="main" sx={{ flexGrow: 1, overflowX: 'hidden' }}>
          {children}
        </Box>
      </Box>
    </div>
  );
};

export default Layout;

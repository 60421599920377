import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import ReactMarkdown from 'react-markdown';
import SendIcon from '@mui/icons-material/Send';
import StandardTextfield from "../standardTextfield";

export default function Step5({ onNext, chatMessages, setChatMessages, innovations }) {
  const [error, setError] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [isTyping, setIsTyping] = useState(true); // Imposta su true per mostrare typing all'inizio
  const [isBoxVisible, setIsBoxVisible] = useState(false); // Nascondi il box di richiesta del questionario finché il server non risponde
  const messagesEndRef = useRef(null);

  const token = localStorage.getItem("token");

  // Funzione per inviare il messaggio automatico
  const sendSelectedStartupsMessage = async () => {
    try {
      setIsTyping(true); // Mostra l'effetto typing
      const payload = {
        message: "Tell me more about the selected startups"
      };

      const response = await axios.post(
        'https://dev.retailhub.ai/api/v4/advisory/message',
        payload,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data && response.data.answer) {
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { id: prevMessages.length, text: response.data.answer, sender: "Server" },
        ]);
      }

      // Disabilita typing e mostra il box per il questionario dopo che il server ha risposto
      setTimeout(() => {
        setIsTyping(false);
        setIsBoxVisible(true); // Mostra il box del questionario
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 1000); // Ritardo per simulare il typing

    } catch (error) {
      setError('Error sending message');
      setIsTyping(false);
    }
  };

  useEffect(() => {
    sendSelectedStartupsMessage();
  }, []); // Chiama il messaggio automatico una volta quando il componente monta

  // Funzione per inviare nuovi messaggi
  const handleAddMessage = async () => {
    if (newMessage.trim() !== "") {
      setChatMessages(prevMessages => [
        ...prevMessages,
        { id: prevMessages.length, text: newMessage, sender: "You" }
      ]);

      setIsTyping(true);

      try {
        const payload = { message: newMessage };
        const response = await axios.post(
          "https://dev.retailhub.ai/api/v4/advisory/message",
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        setTimeout(() => {
          if (response.data && response.data.answer) {
            setChatMessages(prevMessages => [
              ...prevMessages,
              { id: prevMessages.length, text: response.data.answer, sender: "Server" }
            ]);
          }
          setIsTyping(false);
        }, 1000);

      } catch (error) {
        console.error("Error sending message:", error);
        setError("Error sending message");
        setIsTyping(false);
      }

      setNewMessage("");
    }
  };

  const handleNewMessage = (event) => {
    setNewMessage(event.target.value);
  };

  const handleGoToEvaluation = () => {
    onNext(6);
  };

  // Funzione per tornare allo Step 1
  const handleBackToStep1 = async () => {
    try {
      setIsTyping(true); 
      onNext(1);
    } catch (error) {
      window.location.reload();
      console.error('Error changing to step 1:', error);
    } finally {
      setIsTyping(false); 
    }
  };

  return (
    <div className="flex flex-col h-full w-full">
      {/* Chat Container */}
      <div className="flex flex-col flex-grow h-0">
        {/* Chat Messages Area */}
        <div className="flex flex-col flex-grow overflow-y-auto px-5">
          {chatMessages.map((m, index) => (
            <div
              key={`${m.sender}-${index}`}
              className={`flex flex-col gap-1 ${
                m.sender === "You" ? "items-end" : "items-start"
              }`}
            >
              <span className="font-semibold">{m.sender}</span>
              <div className="p-2 bg-gray-800 w-full rounded-2xl max-w-[80%] whitespace-pre-wrap">
                {m.sender === "Server" ? (
                  <ReactMarkdown>{m.text}</ReactMarkdown>
                ) : (
                  <span>{m.text}</span>
                )}
              </div>
            </div>
          ))}

          {/* Effetto typing */}
          {isTyping && (
            <div className="flex flex-col gap-1 items-start mt-4">
              <span className="font-semibold">Server</span>
              <div className="p-2 bg-gray-800 rounded-2xl max-w-[80%] flex flex-col">
                <span>Typing...</span>
              </div>
            </div>
          )}

          {/* Box per andare al questionario - Appare solo dopo la risposta del server */}
          {!isTyping && isBoxVisible && (
            <div className="p-5 bg-gray-800 rounded-2xl mt-4">
              <span className="font-semibold text-white">
                Do you want to proceed with the evaluation questionnaire or start over?
              </span>
              <div className="flex gap-2 mt-4">
                <Button variant="contained" sx={{backgroundColor: "rgb(var(--global-color-primary))", color: "#000"}} onClick={handleGoToEvaluation}>
                  Go to Evaluation Questionnaire
                </Button>
                <Button variant="outlined" sx={{borderColor: "rgb(var(--global-color-primary))", color: "#000"}} onClick={handleBackToStep1}>
                  Restart
                </Button>
              </div>
            </div>
          )}

          {/* Scroll Anchor */}
          <div ref={messagesEndRef} />
        </div>

        {/* Input Area */}
        <div className="p-5 w-full bg-black flex items-center gap-2">
          <StandardTextfield
            variant="outlined"
            multiline={true}
            value={newMessage}
            placeholder="Write here..."
            onChange={handleNewMessage}
            disabled={isTyping}
            style={{ flexGrow: 1 }}
          />

          <Button
            variant="contained"
            sx={{
              backgroundColor: "rgb(var(--global-color-primary))",
              color: "black",
              minWidth: '48px',
              minHeight: '48px',
              '&:hover': {
                backgroundColor: "rgb(var(--global-color-primary))",
              }
            }}
            onClick={handleAddMessage}
            disabled={isTyping}
          >
            <SendIcon />
          </Button>
        </div>
      </div>

      {/* Display error */}
      {error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  );
}

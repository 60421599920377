import * as React from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import StandardButton from './standardButton';
import PaginatedTable from './paginatedTable';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import StandardTextfield from './standardTextfield';
import axios from 'axios';

export default function InviteCollaboratorsDialog({ open, onClose, users, survey_id, startups }) {
  const [openDialog, setOpen] = React.useState(!!open ? open : false);
  const [loading, setLoading] = React.useState(false);
  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [startupsName, setStartupsName] = React.useState(!!startups ? startups.map(s => { return s.name })?.join(", ") : "");
  const [rows, setRows] = React.useState([]);
  const [selectedInterviewees, setSelectedInterviewees] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState(''); 
  const [GoodMessage, setGoodMessage] = React.useState('');

  // Update rows when users prop changes
  React.useEffect(() => {
    if (users && users.length > 0) {
      setRows(
        users.map((u) => {
          return { ...u, id: Number(u.id) };  // Usa il campo 'id' dei dati
        })
      );
    }
  }, [users]);
  

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const header = [
    { field: 'first_name', headerName: 'Firstname', width: 200 },
    { field: 'last_name', headerName: 'Lastname', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  
  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleSaveEdit = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const companyId = localStorage.getItem('companyId');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
  
      // Prepara i dati del nuovo utente
      const newUser = {
        company_id: Number(companyId),
        first_name: firstname,
        last_name: lastname,
        email: email,
      };
  
      // Esegui la richiesta POST per creare il nuovo utente
      const url = `https://dev.retailhub.ai/api/v4/advisory/interviewees`;
      const response = await axios.post(url, newUser, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      // Crea il nuovo utente con l'ID generato dalla risposta
      const createdUser = {
        ...newUser,
        id: Number(response.data.id), // Usa l'ID generato dalla risposta
      };
  
      // Aggiungi il nuovo utente alla lista delle righe esistenti
      setRows((prevRows) => [...prevRows, createdUser]);
  
      // Reset dei campi del form
      handleResetUser();
    } catch (err) {
      console.log('Errore durante il salvataggio:', err);
    } finally {
      setLoading(false);
    }
  };
  


  const handleResetUser = () => {
    setFirstname('');
    setLastname('');
    setEmail('');
  };

  const handleSendInvite = async () => {
    setLoading(true);
    setErrorMessage(''); // Resetta l'errore prima di inviare la richiesta
  
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
  
      // Convert selectedInterviewees IDs to numbers
      const intervieweeIds = selectedInterviewees.map((id) => Number(id));
  
      // Now, add them to the survey
      const data = {
        survey_id,
        interviewees_ids: intervieweeIds,
        message: message || "There is a survey for you",
      };
  
      const inviteUrl = `https://dev.retailhub.ai/api/v4/advisory/interviewees/add-to-survey`;
      const response = await axios.post(inviteUrl, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      // Verifica se la risposta contiene l'array vuoto
      if (response.data.accepted_interviewees.length === 0) {
        setErrorMessage('Il collaboratore selezionato ha già ricevuto il sondaggio');
        setLoading(false);
        return; // Esci senza chiudere la dialog se ci sono errori
      }
  
      if (onClose) {
        setGoodMessage('Invito inviato');
        onClose();
      }
    } catch (err) {
      console.log(err);
      setErrorMessage('Il collaboratore selezionato ha già ricevuto il sondaggio');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <React.Fragment>
      <StandardButton 
        text="Invita altri collaboratori"
        width="350px" 
        variant="filled"
        onClick={handleOpen}
      />
      <Dialog
        open={!!open ? open : openDialog}
        onClose={onClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '16px !important',
            overflow: 'hidden',
            backgroundColor: '#000',
          },
        }}
      >
        <DialogContent
          sx={{
            fontFamily: 'Urbanist, sans-serif',
            backgroundColor: '#000',
          }}
        >
          <div className="flex gap-5 w-full h-full">
            <div className="flex flex-col gap-4 justify-center items-center w-full text-white">
              <span className="text-4xl w-full font-semibold">
                Vuoi far valutare le startup {startupsName} anche ai tuoi collaboratori?
              </span>
              <div className="flex flex-col gap-2 w-full max-h-[600px]">
                <span className="w-full">
                  Seleziona dall'elenco sottostante a chi inviare il sondaggio.
                </span>
                <div className="flex w-full max-h-[270px]">
                  <PaginatedTable
                    header={header}
                    items={rows}
                    checkboxSelection
                    hideFooter
                    selectionModel={selectedInterviewees}
                    onSelectionModelChange={(newSelection) => {
                      setSelectedInterviewees(newSelection);
                      //console.log('Selected IDs:', newSelection);
                    }}
                  />
                </div>
                <span className="w-full">
                  Quando sei pronto clicca qui sotto per inviare il sondaggio ai collaboratori selezionati.
                  <br />
                  Puoi anche aggiungere nella casella di testo qui sotto un messaggio da allegare all'email
                  che riceveranno i tuoi collaboratori.
                </span>
                <StandardTextfield
                  label={'Messaggio personalizzato'}
                  minRows={4}
                  multiline={true}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-5 border-l border-[rgb(var(--global-color-primary))] items-center justify-center w-[500px] text-white pl-5">
              <span className="w-full font-bold text-xl">Aggiungi Collaboratore</span>
              <div className="flex flex-col gap-2 items-center justify-center w-full">
                <StandardTextfield
                  label={'Firstname'}
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                />
                <StandardTextfield
                  label={'Lastname'}
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
                <StandardTextfield
                  label={'Email'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="flex justify-center items-center gap-5 w-full">
                <StandardButton
                  variant="outlined"
                  type="error"
                  text="Cancel"
                  width={'100%'}
                  onClick={handleResetUser}
                />
                <StandardButton
                  text="Save"
                  width={'100%'}
                  onClick={handleSaveEdit}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            fontFamily: 'Urbanist, sans-serif',
            backgroundColor: '#000',
            paddingTop: '40px',
            paddingBottom: '40px',
            justifyContent: 'center',
            gap: '50px',
            display: 'flex',
            flexDirection:'row',
            flexWrap: 'wrap',
            gap: '5px',
          }}
        >
          {/* Visualizza il messaggio di errore se esiste */}
          {errorMessage && (
            <div className={"w-full text-center"} style={{ color: 'red', marginBottom: '20px' }}>
              {errorMessage}
            </div>
          )}

          {GoodMessage && (
            <div className={"w-full text-center"} style={{ color: 'green', marginBottom: '20px' }}>
              {GoodMessage}
            </div>
          )}

          <StandardButton
            variant="outlined"
            onClick={!!onClose ? onClose : handleClose}
            text="Cancel"
            width={'300px'}
            type={'secondary'}
          />
          <StandardButton
            autoFocus={true}
            text="Send"
            width={'300px'}
            onClick={handleSendInvite}
            disabled={selectedInterviewees.length === 0}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

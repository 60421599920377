import React, { useState, useEffect } from 'react';
import BlogSection from '../components/blogSection';
import axios from 'axios';
import { Typography, Pagination } from '@mui/material';

function Blog() {
  const [newsData, setNewsData] = useState([]); // Holds the fetched news articles
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [page, setPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); // Total pages from the API
  const [perPage] = useState(9); // Results per page

  // Function to fetch data
  const fetchData = async (page) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get('https://dev.retailhub.ai/api/v4/news', {
        params: { page, per_page: perPage },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      const data = response.data;
      setNewsData(data.data); // Set the fetched news articles
      setTotalPages(data.last_page); // Set the total pages for pagination
    } catch (err) {
      setError(err.message || 'Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when the page changes
  useEffect(() => {
    fetchData(page);
  }, [page]);

  // Handle page change for pagination
  const handlePageChange = (event, value) => {
    setPage(value); // Update the page number
  };

  return (
    <div className="flex flex-row flex-wrap gap-5" style={{ padding: '25px' }}>
      <div className="bg-black-l p-5 flex flex-col flex basis-full gap-5 justify-center items-center rounded-lg relative">
        <h2>Last news</h2>

        {/* Handle loading and error states */}
        {loading && <Typography>Loading articles...</Typography>}
        {error && <Typography color="error">{error}</Typography>}

        {/* Display the BlogSection with news articles */}
        {!loading && !error && (
          <div className="flex flex-row gap-3">
            <BlogSection articles={newsData} postsPerPage={perPage} showPagination={false} />
          </div>
        )}

        {/* Pagination Component */}
        <div className="flex justify-center mt-6">
          <Pagination
            count={totalPages} // Total pages from the API
            page={page} // Current page
            onChange={handlePageChange} // Handle page change
            color="primary"
            className="pagination"
          />
        </div>
      </div>
    </div>
  );
}

export default Blog;

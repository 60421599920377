import * as React from "react";
import klarnaLogo from '../assets/startups/klarna-logo.png';
import { Checkbox } from '@mui/material';
import StandardButton from "../components/standardButton";
import StandardTextfield from "../components/standardTextfield";
import StandardSelect from "../components/standardSelect";
import FlexibleDataFetcher from '../components/flexibileDataFetcher';

export default function AccountInformation() {
  const [user, setUser] = React.useState({
    id: null,
    firstname: '',
    lastname: '',
    role: '', // ID del ruolo selezionato
    roleOptions: [], // Lista dei ruoli disponibili
    department: '', // ID del dipartimento selezionato
    departmentOptions: [], // Lista dei dipartimenti disponibili
    image: klarnaLogo,
    phone: '',
    email: '',
    faEnabled: false
  });
  const [loading, setLoading] = React.useState(true); // Stato di caricamento

  // Gestisce il cambiamento del ruolo
  const handleChangeRole = (event) => {
    const selectedRoleId = event.target.value;
    setUser((prevUser) => ({
      ...prevUser,
      role: selectedRoleId
    }));
  };

  // Gestisce il cambiamento del dipartimento
  const handleChangeDepartment = (event) => {
    const selectedDepartmentId = event.target.value;
    setUser((prevUser) => ({
      ...prevUser,
      department: selectedDepartmentId
    }));
  };

  // Gestisce il toggle per 2FA
  const handleFAToggle = () => {
    setUser((prevUser) => ({
      ...prevUser,
      faEnabled: !prevUser.faEnabled
    }));
  };

  // Handler per il successo del fetch
  const handleUserFetchSuccess = (data) => {
    const fetchedUser = data;

    // Aggiorna lo stato dell'utente con i dati ricevuti
    setUser((prevUser) => ({
      ...prevUser,
      id: fetchedUser.id || null,
      firstname: fetchedUser.first_name || '',
      lastname: fetchedUser.last_name || '',
      phone: fetchedUser.phone_number || '',
      email: fetchedUser.email || '',
      role: fetchedUser.permissions && fetchedUser.permissions.length > 0 ? fetchedUser.permissions[0].id : '',
      roleOptions: fetchedUser.permissions || [],
      department: fetchedUser.companies && fetchedUser.companies.length > 0 ? fetchedUser.companies[0].id : '',
      departmentOptions: fetchedUser.companies || [],
      image: klarnaLogo, // Puoi aggiornare l'immagine se necessario
      faEnabled: prevUser.faEnabled // Mantiene lo stato attuale di 2FA
    }));

    setLoading(false); 
  };

  // Handler per l'errore nel fetch
  const handleDataFetchError = (error) => {
    console.error("Error fetching user data:", error);
    setLoading(false);
  };

  // Effetto per il debug dello stato utente
  React.useEffect(() => {
    console.log("User state updated:", user);
  }, [user]);

  return (
    <React.Fragment>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="bg-black h-full min-h-[100vh] flex p-5">
          <div className="flex flex-col gap-4 h-full bg-[rgba(255,255,255,0.1)] p-5 w-full rounded-2xl">
            <h1 className="text-6xl font-medium">Account Information</h1>
            <StandardTextfield 
              label='Firstname' 
              value={user.firstname} 
            />
            <StandardTextfield 
              label='Lastname' 
              value={user.lastname} 
            />
            <StandardSelect 
              label="Role" 
              value={user.role} 
              items={user.roleOptions} 
              onChange={handleChangeRole}
              optionValue="id"
              optionLabel="name"
            />
            <StandardSelect 
              label="Department" 
              value={user.department} 
              items={user.departmentOptions} 
              onChange={handleChangeDepartment}
              optionValue="id"
              optionLabel="name"
            />
            <StandardTextfield 
              label='Phone' 
              value={user.phone} 
            />
            <StandardTextfield 
              label='Email' 
              value={user.email} 
            />
            <div className="flex gap-2 items-center">
              <Checkbox checked={user.faEnabled} onClick={handleFAToggle} sx={{color: "white"}}/>
              <span>Enable 2FA</span>
            </div>
            <div className="flex flex-col gap-4 justify-center items-center">
              <span><span className="text-[rgb(var(--global-color-primary))]">Change</span> the password</span>
              <div className="flex flex-wrap gap-5">
                <StandardButton variant="outlined" text="Reset" width="300px" />
                <StandardButton variant="filled" text="Save" width="300px" />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* FlexibleDataFetcher per recuperare i dati dell'utente */}
      <FlexibleDataFetcher
        endpoint="user"
        onSuccess={handleUserFetchSuccess}
        onError={handleDataFetchError}
      />
      
    </React.Fragment>
  );
}

import React from "react";
import BarChart from "../barChart";
import LineChart from "../lineChart";

function formatDateTime(isoString) {
  const date = new Date(isoString);

  // Format the date part (dd/mm/yyyy)
  const formattedDate = date.toLocaleDateString('en-GB'); // 'en-GB' gives dd/mm/yyyy format

  // Format the time part (HH:mm)
  const formattedTime = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false // 24-hour format
  });

  return `${formattedDate} ${formattedTime}`;
}

export default function SurveyQuestionsChartWidget({data}) {
  const groupedBySurveyAndQuestion = !!data ? data.reduce((acc, item) => {
    const { survey_id, question_id } = item;
    
    // If survey_id is null, we group it under 'null' or any placeholder you choose.
    const surveyKey = survey_id || 'null';
  
    // If the survey_id is not already a key, add it as an array
    if (!acc[surveyKey]) {
      acc[surveyKey] = [];
    }
  
    // If the question_id is not already a key under this survey, add it as an array
    if (!acc[surveyKey][question_id]) {
      acc[surveyKey][question_id] = [];
    }
  
    // Push the current item into the appropriate question array under the survey
    acc[surveyKey][question_id].push(item);
  
    return acc;
  }, {}) : [];
  const result = Object.values(groupedBySurveyAndQuestion);

  return (
    <div className="flex p-5 gap-5 items-center bg-black rounded-2xl w-full h-max max-w-full relative">
      <div className="flex flex-col gap-2 w-full max-w-full overflow-x-auto mr-[300px]">
        <div className="flex items-center gap-5 w-full">
          <div className="h-[150px] w-[120px] min-w-[120px] font-bold flex items-center">
            {!!result[0] && result[0][1] && !!result[0][1][0] ? result[0][1][0].title : "Quesito 1"}
          </div>
          <div className="flex gap-1 w-full">
            {
              !!data &&
              result.filter(d => !!d[1] && !!d[1][0] && !!d[1][0].survey_id).map(d =>
                <BarChart key={d[1][0].survey_timestamp} title={`${formatDateTime(d[1][0].survey_timestamp) || ""}`} data={d[1]}/>
              )
            }
          </div>
        </div>
        <div className="flex items-center gap-5 w-full">
          <div className="h-[150px] w-[120px] min-w-[120px] font-bold flex items-center">
            {!!result[0] && result[0][2] && !!result[0][2][0] ? result[0][2][0].title : "Quesito 2"}
          </div>
          <div className="flex gap-1 w-full">
            {
              !!data &&
              result.filter(d => !!d[2] && !!d[2][0] && !!d[2][0].survey_id).map(d =>
                <BarChart key={d[2][0].survey_timestamp} data={d[2]} />
              )
            }
          </div>
        </div>
        <div className="flex items-center gap-5 w-full">
          <div className="h-[150px] w-[120px] min-w-[120px] font-bold flex items-center">
            {!!result[0] && result[0][3] && !!result[0][3][0] ? result[0][3][0].title : "Quesito 3"}
          </div>
          <div className="flex gap-1 w-full">
            {
              !!data &&
              result.filter(d => !!d[3] && !!d[3][0] && !!d[3][0].survey_id).map(d =>
                <BarChart key={d[3][0].survey_timestamp} data={d[3]} />
              )
            }
          </div>
        </div>
        <div className="flex items-center gap-5 w-full">
          <div className="h-[150px] w-[120px] min-w-[120px] font-bold flex items-center">
            {!!result[0] && result[0][4] && !!result[0][4][0] ? result[0][4][0].title : "Quesito 4"}
          </div>
          <div className="flex gap-1 w-full">
            {
              !!data &&
              result.filter(d => !!d[4] && !!d[4][0] && !!d[4][0].survey_id).map(d =>
                <BarChart key={d[4][0].survey_timestamp} data={d[4]} />
              )
            }
          </div>
        </div>
        <div className="flex items-center gap-5 w-full">
          <div className="h-[150px] w-[120px] min-w-[120px] font-bold flex items-center">
            {!!result[0] && result[0][5] && !!result[0][5][0] ? result[0][5][0].title : "Quesito 5"}
          </div>
          <div className="flex gap-1 w-full">
            {
              !!data &&
              result.filter(d => !!d[5] && !!d[5][0] && !!d[5][0].survey_id).map(d =>
                <BarChart key={d[5][0].survey_timestamp} data={d[5]} />
              )
            }
          </div>
        </div>
        <div className="flex items-center gap-5 w-full">
          <div className="h-[150px] w-[120px] min-w-[120px] font-bold flex items-center">
            {!!result[0] && result[0][6] && !!result[0][6][0] ? result[0][6][0].title : "Quesito 6"}
          </div>
          <div className="flex gap-1 w-full">
            {
              !!data &&
              result.filter(d => !!d[6] && !!d[6][0] && !!d[6][0].survey_id).map(d =>
                <BarChart key={d[6][0].survey_timestamp} data={d[6]} />
              )
            }
          </div>
        </div>
        <div className="flex items-center gap-5 w-full">
          <div className="h-[150px] w-[120px] min-w-[120px] font-bold flex items-center">
            {!!result[0] && result[0][7] && !!result[0][7][0] ? result[0][7][0].title : "Quesito 7"}
          </div>
          <div className="flex gap-1 w-full">
            {
              !!data &&
              result.filter(d => !!d[7] && !!d[7][0] && !!d[7][0].survey_id).map(d =>
                <BarChart key={d[7][0].survey_timestamp} data={d[7]} />
              )
            }
          </div>
        </div>
        <div className="flex items-center gap-5 w-full">
          <div className="h-[150px] w-[120px] min-w-[120px] font-bold flex items-center">
            {!!result[0] && result[0][8] && !!result[0][8][0] ? result[0][8][0].title : "Quesito 8"}
          </div>
          <div className="flex gap-1 w-full">
            {
              !!data &&
              result.filter(d => !!d[8] && !!d[8][0] && !!d[8][0].survey_id).map(d =>
                <BarChart key={d[8][0].survey_timestamp} data={d[8]} />
              )
            }
          </div>
        </div>
        <div className="flex items-center gap-5 w-full">
          <div className="h-[150px] w-[120px] min-w-[120px] font-bold flex items-center">
            {!!result[0] && result[0][9] && !!result[0][9][0] ? result[0][9][0].title : "Quesito 9"}
          </div>
          <div className="flex gap-1 w-full">
            {
              !!data &&
              result.filter(d => !!d[9] && !!d[9][0] && !!d[9][0].survey_id).map(d =>
                <BarChart key={d[9][0].survey_timestamp} data={d[9]} />
              )
            }
          </div>
        </div>
        <div className="flex items-center gap-5 w-full">
          <div className="h-[150px] w-[120px] min-w-[120px] font-bold flex items-center">
            {!!result[0] && result[0][10] && !!result[0][10][0] ? result[0][10][0].title : "Quesito 10"}
          </div>
          <div className="flex gap-1 w-full">
            {
              !!data &&
              result.filter(d => !!d[10] && !!d[10][0] && !!d[10][0].survey_id).map(d =>
                <BarChart key={d[10][0].survey_timestamp} data={d[10]} />
              )
            }
          </div>
        </div>
        <div className="flex items-center gap-5 w-full">
          <div className="h-[150px] w-[120px] min-w-[120px] font-bold flex items-center">
            {!!result[0] && result[0][11] && !!result[0][11][0] ? result[0][11][0].title : "Quesito 11"}
          </div>
          <div className="flex gap-1 w-full">
            {
              !!data &&
              result.filter(d => !!d[11] && !!d[11][0] && !!d[11][0].survey_id).map(d =>
                <BarChart key={d[11][0].survey_timestamp} data={d[11]} />
              )
            }
          </div>
        </div>
        <div className="flex items-center gap-5 w-full">
          <div className="h-[150px] w-[120px] min-w-[120px] font-bold flex items-center">
            {!!result[0] && result[0][12] && !!result[0][12][0] ? result[0][12][0].title : "Quesito 12"}
          </div>
          <div className="flex gap-1 w-full">
            {
              !!data &&
              result.filter(d => !!d[12] && !!d[12][0] && !!d[12][0].survey_id).map(d =>
                <BarChart key={d[12][0].survey_timestamp} data={d[12]} />
              )
            }
          </div>
        </div>
      </div>
      <div className="bg-[rgba(0,0,0,0.9)] rounded-e-2xl absolute top-0 right-0 h-full w-[300px] p-5 flex flex-col gap-2">
        <span className="w-full text-center">Distribuzione estrapolata</span>
        {
          !!result[result.length - 1] &&
          result[result.length - 1].map(r =>
            <LineChart key={r[0].question_id} data={r}/>
          )
        }
      </div>
    </div>
  )
}
import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import axios from 'axios';

export default function SectorFilterDialog({ onCategorySelect }) {
  const [open, setOpen] = useState(false);
  const [sectors, setSectors] = useState([]); // Stato per i dati dei settori dall'API

  const [selectedSectorCategories, setSelectedSectorCategories] = useState([]);
  const [selectedSectorSubCategories, setSelectedSectorSubCategories] = useState([]);

  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  // Funzione per recuperare i dati dall'API
  const fetchSectors = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token non trovato. Effettua il login.');
      }
      const response = await axios.get('https://dev.retailhub.ai/api/v4/taxonomies?type=category', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSectors(response.data.data);
    } catch (error) {
      console.error('Errore nel recupero dei settori:', error);
    }
  };

  useEffect(() => {
    fetchSectors();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // Resetta le selezioni quando si chiude il dialogo
    setSelectedSector(null);
    setSelectedCategory(null);
    setSelectedSubCategory(null);
    setSelectedSectorCategories([]);
    setSelectedSectorSubCategories([]);
  };

  const handleSectorSelection = (sector) => {
    setSelectedSector(sector);
    setSelectedCategory(null);
    setSelectedSubCategory(null);
    setSelectedSectorCategories(sector.children || []);
    setSelectedSectorSubCategories([]);
  };

  const handleCategorySelection = (category) => {
    setSelectedCategory(category);
    setSelectedSubCategory(null);
    setSelectedSectorSubCategories(category.children || []);
  };

  const handleSubCategorySelection = (subCategory) => {
    setSelectedSubCategory(subCategory);
  };

  const handleApply = () => {
    let selectedId = null;
    if (selectedSubCategory) {
      selectedId = selectedSubCategory.id;
    } else if (selectedCategory) {
      selectedId = selectedCategory.id;
    } else if (selectedSector) {
      selectedId = selectedSector.id;
    }
    if (onCategorySelect && selectedId) {
      onCategorySelect(selectedId);
    }
    setOpen(false);
  };

  const buttonStyles = {
    fontFamily: 'Urbanist, sans-serif',
    border: open ? '1px solid rgb(var(--global-color-primary))' : '1px solid rgb(255, 255, 255, .3)',
    color: open ? 'rgb(var(--global-color-primary))' : 'rgb(255, 255, 255, .3)',
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={buttonStyles}
      >
        Sectors
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '16px !important',
            overflow: 'hidden',
            backgroundColor: '#191919',
          },
        }}
      >
        <DialogContent sx={{ fontFamily: 'Urbanist, sans-serif', backgroundColor: '#191919' }}>
          <div className="flex flex-wrap gap-4 lg:grid lg:grid-cols-2 xl:grid-cols-3">
            {/* Livello 1: Settori */}
            <div className="flex flex-col gap-2 justify-start items-start">
              <h1 className="text-xl">Sectors</h1>
              {sectors.map((sector) => (
                <Button
                  sx={{
                    color: selectedSector?.id === sector.id ? 'rgb(var(--global-color-primary))' : 'white',
                    padding: '0',
                    textTransform: 'none',
                    justifyContent: 'space-between',
                    fontFamily: 'Urbanist, sans-serif',
                  }}
                  key={sector.id}
                  onClick={() => {
                    handleSectorSelection(sector);
                  }}
                >
                  {sector.name}
                  {sector.children && sector.children.length > 0 && <span>{'>'}</span>}
                </Button>
              ))}
            </div>

            {/* Livello 2: Categorie */}
            {selectedSectorCategories.length > 0 && (
              <div className="flex flex-col gap-2 justify-start items-start">
                <h1 className="text-xl">Areas of Interest</h1>
                {selectedSectorCategories.map((category) => (
                  <Button
                    sx={{
                      color: selectedCategory?.id === category.id ? 'rgb(var(--global-color-primary))' : 'white',
                      padding: '0',
                      textTransform: 'none',
                      justifyContent: 'space-between',
                      fontFamily: 'Urbanist, sans-serif',
                    }}
                    key={category.id}
                    onClick={() => {
                      handleCategorySelection(category);
                    }}
                  >
                    {category.name}
                    {category.children && category.children.length > 0 && <span>{'>'}</span>}
                  </Button>
                ))}
              </div>
            )}

            {/* Livello 3: Sottocategorie */}
            {selectedSectorSubCategories.length > 0 && (
              <div className="flex flex-col gap-2 justify-start items-start">
                <h1 className="text-xl">Technologies</h1>
                {selectedSectorSubCategories.map((subCategory) => (
                  <Button
                    sx={{
                      color: selectedSubCategory?.id === subCategory.id ? 'rgb(var(--global-color-primary))' : 'white',
                      padding: '0',
                      textTransform: 'none',
                      justifyContent: 'space-between',
                      fontFamily: 'Urbanist, sans-serif',
                    }}
                    key={subCategory.id}
                    onClick={() => {
                      handleSubCategorySelection(subCategory);
                    }}
                  >
                    {subCategory.name}
                    {subCategory.children && subCategory.children.length > 0 && <span>{'>'}</span>}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions sx={{ fontFamily: 'Urbanist, sans-serif', backgroundColor: '#191919' }}>
          <Button variant="outlined" sx={{borderColor: "rgb(var(--global-color-primary))", color: "#fff"}} onClick={handleClose}>
            Reset
          </Button>
          <Button variant="contained" sx={{backgroundColor: "rgb(var(--global-color-primary))", color: "#000"}} onClick={handleApply}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

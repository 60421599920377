import React from "react";
import BubbleChart from "../bubbleChart";

export default function AdvisoryBubbleChartRankingWidget({ bubbleChartDataLeft, bubbleChartDataRight }) {

  const innovations = bubbleChartDataLeft ? bubbleChartDataLeft.innovationsList : [];

  return (
    <div className="flex flex-col items-center bg-black rounded-2xl w-full max-w-full p-5">
      <div className="flex flex-wrap xl:flex-nowrap gap-5 items-center w-full max-w-full">
        
        {/* Bubble Chart di sinistra */}
        <div className="flex w-full items-center">
          <span className="text-xs font-black">{bubbleChartDataLeft ? bubbleChartDataLeft.outsideLabel : 'Label 1'}</span>
          <BubbleChart
            title={bubbleChartDataLeft ? bubbleChartDataLeft.title : 'Title 1'}
            datasets={bubbleChartDataLeft ? bubbleChartDataLeft.datasets : []}
          />
        </div>
        {/* Bubble Chart di destra */}
        <div className="flex w-full items-center">
          <span className="text-xs font-black">{bubbleChartDataRight ? bubbleChartDataRight.outsideLabel : 'Label 2'}</span>
          <BubbleChart
            title={bubbleChartDataRight ? bubbleChartDataRight.title : 'Title 2'}
            datasets={bubbleChartDataRight ? bubbleChartDataRight.datasets : []}
          />
        </div>
      </div>
      <div className="w-full flex gap-5 justify-center flex-wrap">
        {
          innovations.map(s => 
            <div 
              key={s.innovation_id} 
              className={`p-2 border-2 border-solid rounded-lg`}
              style={{
                borderColor: s.color
              }}
            >
              {s.innovation_description}
            </div>
          )
        }
      </div>
    </div>
  );
}

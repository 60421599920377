import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

export default function PointChart({title, datasets}) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");

    chartInstance.current = new Chart(myChartRef, {
      type: "scatter",
      data: {
        datasets: !!datasets ? datasets : [
          {
            label: "Klarna",
            data: [
              {
                x: -10,
                y: 0
              }, 
              {
                x: 0,
                y: 10
              }, 
              {
                x: 10,
                y: 5
              }, 
              {
                x: 0.5,
                y: 5.5
              }
            ],
            backgroundColor: "rgb(152, 238, 172, .5)",
            borderColor: "rgb(152, 238, 172)"
          },
        ],
      },
      options: {
        scales: {
          x: {
            grid:{
              color: 'rgb(255, 255, 255, .0)'
            },
            ticks: {
              display: false
            },
            min:0,
            max:10,
          },
          y: {
            grid:{
              color: 'rgb(255, 255, 255, .0)'
            },
            ticks: {
              display: false
            },
            min:0,
            max:10,
          }
        },
        plugins: {
          legend: {
            display: false
          },
          // title: {
          //   display: !!title ? true : false,
          //   text: !!title ? title : "",
          //   color: "white"
          // }
        }
      }
    });
  }, []);

  return (
    <div className='w-full h-[400px] flex flex-col items-center justify-center'>
      <span className="text-xs font-black">{title || ""}</span>
      <div className="relative w-full">
        <div className="flex w-full aspect-[2/1] absolute top-0 left-0" style={{transform: 'translateZ(-10px)'}}>
          <div className="flex flex-col w-full">
            <div className="w-full h-full bg-yellow-700 bg-opacity-50" />
            <div className="w-full h-full bg-red-700 bg-opacity-50" />
          </div>
          <div className="flex flex-col w-full">
            <div className="w-full h-full bg-green-700 bg-opacity-50" />
            <div className="w-full h-full bg-blue-700 bg-opacity-50" />
          </div>
        </div>
        <canvas ref={chartRef} style={{transform: 'translateZ(10px)'}}/>
      </div>
    </div>
  );
}
